export const SAVE_EMPLOYEE = "SAVE_EMPLOYEE"
export const SAVE_EMPLOYEE_FAIL = "SAVE_EMPLOYEE_FAIL"
export const SAVE_EMPLOYEE_SUCCESS = "SAVE_EMPLOYEE_SUCCESS"
export const SAVE_EMPLOYEE_RESET = "SAVE_EMPLOYEE_RESET"

export const saveEmployee = (params) => {
    return { type: SAVE_EMPLOYEE, params }
}
export const saveEmployeeFail = (response) => {
    return { type: SAVE_EMPLOYEE_FAIL, response }
}
export const saveEmployeeSuccess = (response) => {
    return { type: SAVE_EMPLOYEE_SUCCESS, response }
}
export const saveEmployeeReset = () => {
    return { type: SAVE_EMPLOYEE_RESET }
}