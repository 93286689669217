export const GET_CONTAINER_DETAIL = "GET_CONTAINER_DETAIL"
export const GET_CONTAINER_DETAIL_FAIL = "GET_CONTAINER_DETAIL_FAIL"
export const GET_CONTAINER_DETAIL_SUCCESS = "GET_CONTAINER_DETAIL_SUCCESS"
export const GET_CONTAINER_DETAIL_RESET = "GET_CONTAINER_DETAIL_RESET"

export const getContainerDetail = (params) => {
    return { type: GET_CONTAINER_DETAIL, params }
}
export const getContainerDetailFail = (response) => {
    return { type: GET_CONTAINER_DETAIL_FAIL, response }
}
export const getContainerDetailSuccess = (response) => {
    return { type: GET_CONTAINER_DETAIL_SUCCESS, response }
}
export const getContainerDetailReset = () => {
    return { type: GET_CONTAINER_DETAIL_RESET }
}