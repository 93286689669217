// action
import {
    GET_CONSULATION_DASHBOARD_DETAIL,
    GET_CONSULATION_DASHBOARD_DETAIL_FAIL,
    GET_CONSULATION_DASHBOARD_DETAIL_SUCCESS,
    GET_CONSULATION_DASHBOARD_DETAIL_RESET
} from '../action/getConsulationDashboardDetailAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONSULATION_DASHBOARD_DETAIL:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_CONSULATION_DASHBOARD_DETAIL_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_CONSULATION_DASHBOARD_DETAIL_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data.result,
            }
        case GET_CONSULATION_DASHBOARD_DETAIL_RESET:
            return initialState
        default:
            return state
    }
}