export const DELETE_PAGE_CONTENT = "DELETE_PAGE_CONTENT"
export const DELETE_PAGE_CONTENT_FAIL = "DELETE_PAGE_CONTENT_FAIL"
export const DELETE_PAGE_CONTENT_SUCCESS = "DELETE_PAGE_CONTENT_SUCCESS"
export const DELETE_PAGE_CONTENT_RESET = "DELETE_PAGE_CONTENT_RESET"

export const deletePageContent = (params) => {
    return { type: DELETE_PAGE_CONTENT, params }
}
export const deletePageContentFail = (response) => {
    return { type: DELETE_PAGE_CONTENT_FAIL, response }
}
export const deletePageContentSuccess = (response) => {
    return { type: DELETE_PAGE_CONTENT_SUCCESS, response }
}
export const deletePageContentReset = () => {
    return { type: DELETE_PAGE_CONTENT_RESET }
}