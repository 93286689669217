export const GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER = "GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER"
export const GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_FAIL = "GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_FAIL"
export const GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_SUCCESS = "GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_SUCCESS"
export const GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_RESET = "GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_RESET"

export const getInstituteTestPricingUploadMaster = (params) => {
    return { type: GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER, params }
}
export const getInstituteTestPricingUploadMasterFail = (response) => {
    return { type: GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_FAIL, response }
}
export const getInstituteTestPricingUploadMasterSuccess = (response) => {
    return { type: GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_SUCCESS, response }
}
export const getInstituteTestPricingUploadMasterReset = () => {
    return { type: GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER_RESET }
}