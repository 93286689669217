export const GET_EMPLOYEE_DROPDOWN = "GET_EMPLOYEE_DROPDOWN"
export const GET_EMPLOYEE_DROPDOWN_FAIL = "GET_EMPLOYEE_DROPDOWN_FAIL"
export const GET_EMPLOYEE_DROPDOWN_SUCCESS = "GET_EMPLOYEE_DROPDOWN_SUCCESS"
export const GET_EMPLOYEE_DROPDOWN_RESET = "GET_EMPLOYEE_DROPDOWN_RESET"

export const getEmployeeDropdown = (params) => {
    return { type: GET_EMPLOYEE_DROPDOWN, params }
}
export const getEmployeeDropdownFail = (response) => {
    return { type: GET_EMPLOYEE_DROPDOWN_FAIL, response }
}
export const getEmployeeDropdownSuccess = (response) => {
    return { type: GET_EMPLOYEE_DROPDOWN_SUCCESS, response }
}
export const getEmployeeDropdownReset = () => {
    return { type: GET_EMPLOYEE_DROPDOWN_RESET }
}