export const GET_SAMPLE_DETAIL = "GET_SAMPLE_DETAIL"
export const GET_SAMPLE_DETAIL_FAIL = "GET_SAMPLE_DETAIL_FAIL"
export const GET_SAMPLE_DETAIL_SUCCESS = "GET_SAMPLE_DETAIL_SUCCESS"
export const GET_SAMPLE_DETAIL_RESET = "GET_SAMPLE_DETAIL_RESET"

export const getSampleDetail = (params) => {
    return { type: GET_SAMPLE_DETAIL, params }
}
export const getSampleDetailFail = (response) => {
    return { type: GET_SAMPLE_DETAIL_FAIL, response }
}
export const getSampleDetailSuccess = (response) => {
    return { type: GET_SAMPLE_DETAIL_SUCCESS, response }
}
export const getSampleDetailReset = () => {
    return { type: GET_SAMPLE_DETAIL_RESET }
}