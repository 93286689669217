export const GET_EMPLOYEE_DETAIL = "GET_EMPLOYEE_DETAIL"
export const GET_EMPLOYEE_DETAIL_FAIL = "GET_EMPLOYEE_DETAIL_FAIL"
export const GET_EMPLOYEE_DETAIL_SUCCESS = "GET_EMPLOYEE_DETAIL_SUCCESS"
export const GET_EMPLOYEE_DETAIL_RESET = "GET_EMPLOYEE_DETAIL_RESET"

export const getEmployeeDetail = (params) => {
    return { type: GET_EMPLOYEE_DETAIL, params }
}
export const getEmployeeDetailFail = (response) => {
    return { type: GET_EMPLOYEE_DETAIL_FAIL, response }
}
export const getEmployeeDetailSuccess = (response) => {
    return { type: GET_EMPLOYEE_DETAIL_SUCCESS, response }
}
export const getEmployeeDetailReset = () => {
    return { type: GET_EMPLOYEE_DETAIL_RESET }
}