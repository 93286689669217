export const DELETE_QUICK_SUPPORT_QUESTION_ANSWER = "DELETE_QUICK_SUPPORT_QUESTION_ANSWER"
export const DELETE_QUICK_SUPPORT_QUESTION_ANSWER_FAIL = "DELETE_QUICK_SUPPORT_QUESTION_ANSWER_FAIL"
export const DELETE_QUICK_SUPPORT_QUESTION_ANSWER_SUCCESS = "DELETE_QUICK_SUPPORT_QUESTION_ANSWER_SUCCESS"
export const DELETE_QUICK_SUPPORT_QUESTION_ANSWER_RESET = "DELETE_QUICK_SUPPORT_QUESTION_ANSWER_RESET"

export const deleteQuickSupportQuestionAnswer = (params) => {
    return { type: DELETE_QUICK_SUPPORT_QUESTION_ANSWER, params }
}
export const deleteQuickSupportQuestionAnswerFail = (response) => {
    return { type: DELETE_QUICK_SUPPORT_QUESTION_ANSWER_FAIL, response }
}
export const deleteQuickSupportQuestionAnswerSuccess = (response) => {
    return { type: DELETE_QUICK_SUPPORT_QUESTION_ANSWER_SUCCESS, response }
}
export const deleteQuickSupportQuestionAnswerReset = () => {
    return { type: DELETE_QUICK_SUPPORT_QUESTION_ANSWER_RESET }
}