export const GET_SAMPLE_CONTAINER_TEST_MAP_LIST = "GET_SAMPLE_CONTAINER_TEST_MAP_LIST"
export const GET_SAMPLE_CONTAINER_TEST_MAP_LIST_FAIL = "GET_SAMPLE_CONTAINER_TEST_MAP_LIST_FAIL"
export const GET_SAMPLE_CONTAINER_TEST_MAP_LIST_SUCCESS = "GET_SAMPLE_CONTAINER_TEST_MAP_LIST_SUCCESS"
export const GET_SAMPLE_CONTAINER_TEST_MAP_LIST_RESET = "GET_SAMPLE_CONTAINER_TEST_MAP_LIST_RESET"

export const getSampleContainerTestMapList = (params) => {
    return { type: GET_SAMPLE_CONTAINER_TEST_MAP_LIST, params }
}
export const getSampleContainerTestMapListFail = (response) => {
    return { type: GET_SAMPLE_CONTAINER_TEST_MAP_LIST_FAIL, response }
}
export const getSampleContainerTestMapListSuccess = (response) => {
    return { type: GET_SAMPLE_CONTAINER_TEST_MAP_LIST_SUCCESS, response }
}
export const getSampleContainerTestMapListReset = () => {
    return { type: GET_SAMPLE_CONTAINER_TEST_MAP_LIST_RESET }
}