export const SAVE_ROLE = "SAVE_ROLE"
export const SAVE_ROLE_FAIL = "SAVE_ROLE_FAIL"
export const SAVE_ROLE_SUCCESS = "SAVE_ROLE_SUCCESS"
export const SAVE_ROLE_RESET = "SAVE_ROLE_RESET"

export const saveRole = (params) => {
    return { type: SAVE_ROLE, params }
}
export const saveRoleFail = (response) => {
    return { type: SAVE_ROLE_FAIL, response }
}
export const saveRoleSuccess = (response) => {
    return { type: SAVE_ROLE_SUCCESS, response }
}
export const saveRoleReset = () => {
    return { type: SAVE_ROLE_RESET }
}