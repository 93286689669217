export const GET_CONTACT_US_ENQURIES_DETAIL = "GET_CONTACT_US_ENQURIES_DETAIL"
export const GET_CONTACT_US_ENQURIES_DETAIL_FAIL = "GET_CONTACT_US_ENQURIES_DETAIL_FAIL"
export const GET_CONTACT_US_ENQURIES_DETAIL_SUCCESS = "GET_CONTACT_US_ENQURIES_DETAIL_SUCCESS"
export const GET_CONTACT_US_ENQURIES_DETAIL_RESET = "GET_CONTACT_US_ENQURIES_DETAIL_RESET"

export const getContactUsEnquriesDetail = (params) => {
    return { type: GET_CONTACT_US_ENQURIES_DETAIL, params }
}
export const getContactUsEnquriesDetailFail = (response) => {
    return { type: GET_CONTACT_US_ENQURIES_DETAIL_FAIL, response }
}
export const getContactUsEnquriesDetailSuccess = (response) => {
    return { type: GET_CONTACT_US_ENQURIES_DETAIL_SUCCESS, response }
}
export const getContactUsEnquriesDetailReset = () => {
    return { type: GET_CONTACT_US_ENQURIES_DETAIL_RESET }
}