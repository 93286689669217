export const GET_SAMPLE_LIST = "GET_SAMPLE_LIST"
export const GET_SAMPLE_LIST_FAIL = "GET_SAMPLE_LIST_FAIL"
export const GET_SAMPLE_LIST_SUCCESS = "GET_SAMPLE_LIST_SUCCESS"
export const GET_SAMPLE_LIST_RESET = "GET_SAMPLE_LIST_RESET"

export const getSampleList = (params) => {
    return { type: GET_SAMPLE_LIST, params }
}
export const getSampleListFail = (response) => {
    return { type: GET_SAMPLE_LIST_FAIL, response }
}
export const getSampleListSuccess = (response) => {
    return { type: GET_SAMPLE_LIST_SUCCESS, response }
}
export const getSampleListReset = () => {
    return { type: GET_SAMPLE_LIST_RESET }
}