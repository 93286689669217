export const DELETE_CONTAINER = "DELETE_CONTAINER"
export const DELETE_CONTAINER_FAIL = "DELETE_CONTAINER_FAIL"
export const DELETE_CONTAINER_SUCCESS = "DELETE_CONTAINER_SUCCESS"
export const DELETE_CONTAINER_RESET = "DELETE_CONTAINER_RESET"

export const deleteContainer = (params) => {
    return { type: DELETE_CONTAINER, params }
}
export const deleteContainerFail = (response) => {
    return { type: DELETE_CONTAINER_FAIL, response }
}
export const deleteContainerSuccess = (response) => {
    return { type: DELETE_CONTAINER_SUCCESS, response }
}
export const deleteContainerReset = () => {
    return { type: DELETE_CONTAINER_RESET }
}