
export const GET_NEW_DOCTOR_LIST = "GET_NEW_DOCTOR_LIST";
export const GET_NEW_DOCTOR_LIST_SUCCESS = "GET_NEW_DOCTOR_LIST_SUCCESS";
export const GET_NEW_DOCTOR_LIST_FAIL = "GET_NEW_DOCTOR_LIST_FAIL";
export const GET_NEW_DOCTOR_LIST_RESET = 'GET_NEW_DOCTOR_LIST_RESET';


export const getNewDoctorList = (params) => {
    return {
        type: GET_NEW_DOCTOR_LIST,
        params,
    };
};

export const getNewDoctorListSuccess = (response) => {
    return {
        type: GET_NEW_DOCTOR_LIST_SUCCESS,
        response,
    };
};

export const getNewDoctorListFail = (response) => {
    return {
        type: GET_NEW_DOCTOR_LIST_FAIL,
        response,
    };
};

export const getNewDoctorListReset = () => {
	return {
		type: GET_NEW_DOCTOR_LIST_RESET,
	};
};
