export const GET_INSTITUTE_LIST = "GET_INSTITUTE_LIST"
export const GET_INSTITUTE_LIST_FAIL = "GET_INSTITUTE_LIST_FAIL"
export const GET_INSTITUTE_LIST_SUCCESS = "GET_INSTITUTE_LIST_SUCCESS"
export const GET_INSTITUTE_LIST_RESET = "GET_INSTITUTE_LIST_RESET"

export const getInstituteList = (params) => {
    return { type: GET_INSTITUTE_LIST, params }
}
export const getInstituteListFail = (response) => {
    return { type: GET_INSTITUTE_LIST_FAIL, response }
}
export const getInstituteListSuccess = (response) => {
    return { type: GET_INSTITUTE_LIST_SUCCESS, response }
}
export const getInstituteListReset = () => {
    return { type: GET_INSTITUTE_LIST_RESET }
}