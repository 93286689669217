// action
import {
    GET_DTRFS_LIST,
    GET_DTRFS_LIST_FAIL,
    GET_DTRFS_LIST_SUCCESS,
    GET_DTRFS_LIST_RESET
} from '../action/getDtrfsListAction'

// initialState
const initialState = {
    apiState: "",
    message: "",
    list: [],
    pagination: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DTRFS_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_DTRFS_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response.data.message
            }
        case GET_DTRFS_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data.result,
                pagination: action.response.data.pagination
            }
        case GET_DTRFS_LIST_RESET:
            return initialState

        default:
            return state
    }
}