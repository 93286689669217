export const SAVE_DOCTOR = "SAVE_DOCTOR"
export const SAVE_DOCTOR_FAIL = "SAVE_DOCTOR_FAIL"
export const SAVE_DOCTOR_SUCCESS = "SAVE_DOCTOR_SUCCESS"
export const SAVE_DOCTOR_RESET = "SAVE_DOCTOR_RESET"

export const saveDoctor = (params) => {
    return { type: SAVE_DOCTOR, params }
}
export const saveDoctorFail = (response) => {
    return { type: SAVE_DOCTOR_FAIL, response }
}
export const saveDoctorSuccess = (response) => {
    return { type: SAVE_DOCTOR_SUCCESS, response }
}
export const saveDoctorReset = () => {
    return { type: SAVE_DOCTOR_RESET }
}