export const DELETE_COMMON_MASTER = "DELETE_COMMON_MASTER"
export const DELETE_COMMON_MASTER_FAIL = "DELETE_COMMON_MASTER_FAIL"
export const DELETE_COMMON_MASTER_SUCCESS = "DELETE_COMMON_MASTER_SUCCESS"
export const DELETE_COMMON_MASTER_RESET = "DELETE_COMMON_MASTER_RESET"

export const deleteCommonMaster = (params) => {
    return { type: DELETE_COMMON_MASTER, params }
}
export const deleteCommonMasterFail = (response) => {
    return { type: DELETE_COMMON_MASTER_FAIL, response }
}
export const deleteCommonMasterSuccess = (response) => {
    return { type: DELETE_COMMON_MASTER_SUCCESS, response }
}
export const deleteCommonMasterReset = () => {
    return { type: DELETE_COMMON_MASTER_RESET }
}