export const GET_ROLE_MODULE_MAPPING_LIST = "GET_ROLE_MODULE_MAPPING_LIST"
export const GET_ROLE_MODULE_MAPPING_LIST_FAIL = "GET_ROLE_MODULE_MAPPING_LIST_FAIL"
export const GET_ROLE_MODULE_MAPPING_LIST_SUCCESS = "GET_ROLE_MODULE_MAPPING_LIST_SUCCESS"
export const GET_ROLE_MODULE_MAPPING_LIST_RESET = "GET_ROLE_MODULE_MAPPING_LIST_RESET"

export const getRoleModuleMappingList = (params) => {
    return { type: GET_ROLE_MODULE_MAPPING_LIST, params }
}
export const getRoleModuleMappingListFail = (response) => {
    return { type: GET_ROLE_MODULE_MAPPING_LIST_FAIL, response }
}
export const getRoleModuleMappingListSuccess = (response) => {
    return { type: GET_ROLE_MODULE_MAPPING_LIST_SUCCESS, response }
}
export const getRoleModuleMappingListReset = () => {
    return { type: GET_ROLE_MODULE_MAPPING_LIST_RESET }
}