// action
import {
    OTP,
    OTP_FAIL,
    OTP_SUCCESS,
    OTP_RESET
} from '../action/otpAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case OTP:
            return {
                ...state,
                apiState: "loading"
            }
        case OTP_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case OTP_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
            }
        case OTP_RESET:
            return initialState
        default:
            return state
    }
}