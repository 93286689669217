export const GET_PINCODE_LIST = "GET_PINCODE_LIST"
export const GET_PINCODE_LIST_FAIL = "GET_PINCODE_LIST_FAIL"
export const GET_PINCODE_LIST_SUCCESS = "GET_PINCODE_LIST_SUCCESS"
export const GET_PINCODE_LIST_RESET = "GET_PINCODE_LIST_RESET"

export const getPincodeList = (params) => {
    return { type: GET_PINCODE_LIST, params }
}
export const getPincodeListFail = (response) => {
    return { type: GET_PINCODE_LIST_FAIL, response }
}
export const getPincodeListSuccess = (response) => {
    return { type: GET_PINCODE_LIST_SUCCESS, response }
}
export const getPincodeListReset = () => {
    return { type: GET_PINCODE_LIST_RESET }
}