export const SAVE_PAGE_CONTENT = "SAVE_PAGE_CONTENT"
export const SAVE_PAGE_CONTENT_FAIL = "SAVE_PAGE_CONTENT_FAIL"
export const SAVE_PAGE_CONTENT_SUCCESS = "SAVE_PAGE_CONTENT_SUCCESS"
export const SAVE_PAGE_CONTENT_RESET = "SAVE_PAGE_CONTENT_RESET"

export const savePageContent = (params) => {
    return { type: SAVE_PAGE_CONTENT, params }
}
export const savePageContentFail = (response) => {
    return { type: SAVE_PAGE_CONTENT_FAIL, response }
}
export const savePageContentSuccess = (response) => {
    return { type: SAVE_PAGE_CONTENT_SUCCESS, response }
}
export const savePageContentReset = () => {
    return { type: SAVE_PAGE_CONTENT_RESET }
}