// action
import {
    DELETE_ARTICLE,
    DELETE_ARTICLE_FAIL,
    DELETE_ARTICLE_SUCCESS,
    DELETE_ARTICLE_RESET
} from '../action/deleteArticleAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DELETE_ARTICLE:
            return {
                ...state,
                apiState: "loading"
            }
        case DELETE_ARTICLE_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case DELETE_ARTICLE_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
            }
        case DELETE_ARTICLE_RESET:
            return initialState
        default:
            return state
    }
}