import { call, put, takeLatest } from 'redux-saga/effects'

/* OTHERS */
import { handleHttpErr } from "../utils"

// actions
import {
    DELETE_TEST_MASTER,
    deleteTestMasterFail,
    deleteTestMasterSuccess
} from '../action/deleteTestMasterAction'

// api
import deleteTestMasterApi from '../api/deleteTestMasterApi'

export function* deleteTestMasterSaga(action) {
    try {
        const response = yield call(() => deleteTestMasterApi(action.params))
        if (response) {
            yield put(deleteTestMasterSuccess(response, action))
        }
    }
    catch (e) {
        handleHttpErr(e.response)
        yield put(deleteTestMasterFail(e.response, action))

    }
}

export default function* mySaga() {
    yield takeLatest(DELETE_TEST_MASTER, deleteTestMasterSaga)
}