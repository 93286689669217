// action
import {
    SAVE_BULK_UPLOAD,
    SAVE_BULK_UPLOAD_FAIL,
    SAVE_BULK_UPLOAD_SUCCESS,
    SAVE_BULK_UPLOAD_RESET
} from '../action/saveBulkUploadAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_BULK_UPLOAD:
            return {
                ...state,
                apiState: "loading"
            }
        case SAVE_BULK_UPLOAD_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case SAVE_BULK_UPLOAD_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
                data:  action.response.data.data,
            }
        case SAVE_BULK_UPLOAD_RESET:
            return initialState
        default:
            return state
    }
}