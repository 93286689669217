export const DELETE_QUICK_SUPPORT_CATEGORY = "DELETE_QUICK_SUPPORT_CATEGORY"
export const DELETE_QUICK_SUPPORT_CATEGORY_FAIL = "DELETE_QUICK_SUPPORT_CATEGORY_FAIL"
export const DELETE_QUICK_SUPPORT_CATEGORY_SUCCESS = "DELETE_QUICK_SUPPORT_CATEGORY_SUCCESS"
export const DELETE_QUICK_SUPPORT_CATEGORY_RESET = "DELETE_QUICK_SUPPORT_CATEGORY_RESET"

export const deleteQuickSupportCategory = (params) => {
    return { type: DELETE_QUICK_SUPPORT_CATEGORY, params }
}
export const deleteQuickSupportCategoryFail = (response) => {
    return { type: DELETE_QUICK_SUPPORT_CATEGORY_FAIL, response }
}
export const deleteQuickSupportCategorySuccess = (response) => {
    return { type: DELETE_QUICK_SUPPORT_CATEGORY_SUCCESS, response }
}
export const deleteQuickSupportCategoryReset = () => {
    return { type: DELETE_QUICK_SUPPORT_CATEGORY_RESET }
}