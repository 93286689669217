export const SAVE_MOU = "SAVE_MOU"
export const SAVE_MOU_FAIL = "SAVE_MOU_FAIL"
export const SAVE_MOU_SUCCESS = "SAVE_MOU_SUCCESS"
export const SAVE_MOU_RESET = "SAVE_MOU_RESET"

export const saveMou = (params) => {
    return { type: SAVE_MOU, params }
}
export const saveMouFail = (response) => {
    return { type: SAVE_MOU_FAIL, response }
}
export const saveMouSuccess = (response) => {
    return { type: SAVE_MOU_SUCCESS, response }
}
export const saveMouReset = () => {
    return { type: SAVE_MOU_RESET }
}