export const SAVE_ADMIN_TYPE = "SAVE_ADMIN_TYPE"
export const SAVE_ADMIN_TYPE_FAIL = "SAVE_ADMIN_TYPE_FAIL"
export const SAVE_ADMIN_TYPE_SUCCESS = "SAVE_ADMIN_TYPE_SUCCESS"
export const SAVE_ADMIN_TYPE_RESET = "SAVE_ADMIN_TYPE_RESET"

export const saveAdminType = (params) => {
    return { type: SAVE_ADMIN_TYPE, params }
}
export const saveAdminTypeFail = (response) => {
    return { type: SAVE_ADMIN_TYPE_FAIL, response }
}
export const saveAdminTypeSuccess = (response) => {
    return { type: SAVE_ADMIN_TYPE_SUCCESS, response }
}
export const saveAdminTypeReset = () => {
    return { type: SAVE_ADMIN_TYPE_RESET }
}