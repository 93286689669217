import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_SAMPLE,
    deleteSampleFail,
    deleteSampleSuccess
} from '../action/deleteSampleAction'

// api 
import deleteSampleApi from '../api/deleteSampleApi'

export function* deleteSampleSaga(action) {
    try {
        const response = yield call(() => deleteSampleApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteSampleSuccess(response, action))
        } else {
            yield put(deleteSampleFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteSampleFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_SAMPLE, deleteSampleSaga)
}