export const GET_QUICK_SUPPORT_CATEGORY_LIST = "GET_QUICK_SUPPORT_CATEGORY_LIST"
export const GET_QUICK_SUPPORT_CATEGORY_LIST_FAIL = "GET_QUICK_SUPPORT_CATEGORY_LIST_FAIL"
export const GET_QUICK_SUPPORT_CATEGORY_LIST_SUCCESS = "GET_QUICK_SUPPORT_CATEGORY_LIST_SUCCESS"
export const GET_QUICK_SUPPORT_CATEGORY_LIST_RESET = "GET_QUICK_SUPPORT_CATEGORY_LIST_RESET"

export const getQuickSupportCategoryList = (params) => {
    return { type: GET_QUICK_SUPPORT_CATEGORY_LIST, params }
}
export const getQuickSupportCategoryListFail = (response) => {
    return { type: GET_QUICK_SUPPORT_CATEGORY_LIST_FAIL, response }
}
export const getQuickSupportCategoryListSuccess = (response) => {
    return { type: GET_QUICK_SUPPORT_CATEGORY_LIST_SUCCESS, response }
}
export const getQuickSupportCategoryListReset = () => {
    return { type: GET_QUICK_SUPPORT_CATEGORY_LIST_RESET }
}