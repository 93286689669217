export const GET_LIST_TEST_MASTER = "GET_LIST_TEST_MASTER"
export const GET_LIST_TEST_MASTER_FAIL = "GET_LIST_TEST_MASTER_FAIL"
export const GET_LIST_TEST_MASTER_SUCCESS = "GET_LIST_TEST_MASTER_SUCCESS"
export const GET_LIST_TEST_MASTER_RESET = "GET_LIST_TEST_MASTER_RESET"

export const getListTestMaster = (params) => {
    return { type: GET_LIST_TEST_MASTER, params }
}
export const getListTestMasterFail = (response) => {
    return { type: GET_LIST_TEST_MASTER_FAIL, response }
}
export const getListTestMasterSuccess = (response) => {
    return { type: GET_LIST_TEST_MASTER_SUCCESS, response }
}
export const getListTestMasterReset = () => {
    return { type: GET_LIST_TEST_MASTER_RESET }
}