export const SAVE_SYSTEM_USER = "SAVE_SYSTEM_USER"
export const SAVE_SYSTEM_USER_FAIL = "SAVE_SYSTEM_USER_FAIL"
export const SAVE_SYSTEM_USER_SUCCESS = "SAVE_SYSTEM_USER_SUCCESS"
export const SAVE_SYSTEM_USER_RESET = "SAVE_SYSTEM_USER_RESET"

export const saveSystemUser = (params) => {
    return { type: SAVE_SYSTEM_USER, params }
}
export const saveSystemUserFail = (response) => {
    return { type: SAVE_SYSTEM_USER_FAIL, response }
}
export const saveSystemUserSuccess = (response) => {
    return { type: SAVE_SYSTEM_USER_SUCCESS, response }
}
export const saveSystemUserReset = () => {
    return { type: SAVE_SYSTEM_USER_RESET }
}