import { combineReducers } from 'redux'

// reducers
import saveTestMaster from './reducer/saveTestMasterReducer'
import getListTestMaster from './reducer/getListTestMasterReducer'
import deleteTestMaster from './reducer/deleteTestMasterReducer'
import viewTestMaster from './reducer/viewTestMasterReducer'
import saveInstitute from './reducer/saveInstituteReducer'
import getInstituteList from './reducer/getInstituteListReducer'
import getInstituteDetail from './reducer/getInstituteDetailReducer'
import deleteInstitute from './reducer/deleteInstituteReducer'
import getDoctorList from './reducer/getDoctorListReducer'
import deleteDoctor from './reducer/deleteDoctorReducer'
import getDoctorDetail from './reducer/getDoctorDetailReducer'
import saveDoctor from './reducer/saveDoctorReducer'
import saveSample from './reducer/saveSampleReducer'
import getSampleList from './reducer/getSampleListReducer'
import getSampleDetail from './reducer/getSampleDetailReducer'
import deleteSample from './reducer/deleteSampleReducer'
import saveInstituteTestPricingMaster from './reducer/saveInstituteTestPricingMasterReducer'
import getInstituteTestPricingMaster from './reducer/getInstituteTestPricingMasterReducer'
import deleteInstituteTestPricingMaster from './reducer/deleteInstituteTestPricingMasterReducer'
import getInstituteTestPricingDetail from './reducer/getInstituteTestPricingDetailReducer'
import saveArticle from './reducer/saveArticleReducer'
import getArticleList from './reducer/getArticleListReducer'
import deleteArticle from './reducer/deleteArticleReducer'
import getArticleDetail from './reducer/getArticleDetailReducer'
import savePregnancyHighlights from './reducer/savePregnancyHighlightsReducer'
import getPregnancyHighlightsList from './reducer/getPregnancyHighlightsListReducer'
import deletePregnancyHighlights from './reducer/deletePregnancyHighlightsReducer'
import getPregnancyHighlightsDetail from './reducer/getPregnancyHighlightsDetailReducer'
import login from './reducer/loginReducer'
import logout from './reducer/logoutReducer'
import getCommonMasterList from './reducer/getCommonMasterListReducer'
import saveCommonMaster from './reducer/saveCommonMasterReducer'
import deleteCommonMaster from './reducer/deleteCommonMasterReducer'
import getPageContentList from './reducer/getPageContentListReducer'
import savePageContent from './reducer/savePageContentReducer'
import getPageContentDetail from './reducer/getPageContentDetailReducer'
import deletePageContent from './reducer/deletePageContentReducer'
import getUserTermAndConditionList from './reducer/getUserTermAndConditionListReducer'
import getUserTermAndConditionDetail from './reducer/getUserTermAndConditionDetailReducer'
import saveSystemUser from './reducer/saveSystemUserReducer'
import systemUserList from './reducer/systemUserListReducer'
import systemUserDetail from './reducer/systemUserDetailReducer'
import saveUserRights from './reducer/saveUserRightsReducer'
import saveBulkUpload from './reducer/saveBulkUploadReducer'
import getEmployeeList from './reducer/getEmployeeListReducer'
import getEmployeeDropdown from './reducer/getEmployeeDropdownReducer'
import deleteEmployee from './reducer/deleteEmployeeReducer'
import getEmployeeDetail from './reducer/getEmployeeDetailReducer'
import saveEmployee from './reducer/saveEmployeeReducer'
import getCouponList from './reducer/getCouponListReducer'
import deleteCoupon from './reducer/deleteCouponReducer'
import getCouponDetail from './reducer/getCouponDetailReducer'
import saveCoupon from './reducer/saveCouponReducer'
import getTestimonialList from './reducer/getTestMonialListReducer'
import changeTestimonialStatus from './reducer/changeTestimonialStatusReducer'
import getPincodeDetail from './reducer/getPincodeDetailReducer'
import getPincodeList from './reducer/getPincodeListReducer'
import getPatientList from './reducer/getPatientListReducer'
import getPatientDetail from './reducer/getPatientDetailReducer'
import savePatient from './reducer/savePatientReducer'
import getConsulationDashboardList from './reducer/getConsulationDashboardListReducer'
import getConsulationDashboardDetail from './reducer/getConsulationDashboardDetailReducer'
import getPaymentHistory from './reducer/getPaymentHistoryReducer'
import getPaymentHistoryCoupon from './reducer/getPaymentHistoryCouponReducer'
import getChatLog from './reducer/getChatLogReducer'
import saveContainer from './reducer/saveContainerReducer'
import getContainerList from './reducer/getContainerListReducer'
import getContainerDetail from './reducer/getContainerDetailReducer'
import deleteContainer from './reducer/deleteContainerReducer'
import saveSampleContainerTestMap from './reducer/saveSampleContainerTestMapReducer'
import getSampleContainerTestMapList from './reducer/getSampleContainerTestMapListReducer'
import getSampleContainerTestMapDetail from './reducer/getSampleContainerTestMapDetailReducer'
import deleteSampleContainerTestMap from './reducer/deleteSampleContainerTestMapReducer'
import getQuickSupportCategoryList from './reducer/getQuickSupportCategoryListReducer'
import saveQuickSupportCategory from './reducer/saveQuickSupportCategoryReducer'
import saveAdminType from './reducer/saveAdminTypeReducer'
import getAdminTypeDetail from './reducer/getAdminTypeDetailReducer'
import getAdminTypeList from './reducer/getAdminTypeListReducer'
import deleteAdminType from './reducer/deleteAdminTypeReducer'
import saveRole from './reducer/saveRoleReducer'
import getRoleList from './reducer/getRoleListReducer'
import getRoleDetail from './reducer/getRoleDetailReducer'
import deleteRole from './reducer/deleteRoleReducer'
import deleteModule from './reducer/deleteModuleReducer'
import saveModule from './reducer/saveModuleReducer'
import getModuleList from './reducer/getModuleListReducer'
import getModuleDetail from './reducer/getModuleDetailReducer'
import saveRoleModuleMapping from './reducer/saveRoleModuleMappingReducer'
import getRoleModuleMappingList from './reducer/getRoleModuleMappingListReducer'
import getRoleModuleMappingDetail from './reducer/getRoleModuleMappingDetailReducer'
import deleteRoleModuleMapping from './reducer/deleteRoleModuleMappingReducer'
import getRoleModuleMappingDetailByRole from './reducer/getRoleModuleMappingDetailByRoleReducer'
import getQuickSupportCategoryDetail from './reducer/getQuickSupportCategoryDetailReducer'
import getQuickSupportQuestionAnswerList from './reducer/getQuickSupportQuestionAnswerListReducer'
import saveQuickSupportQuestionAnswer from './reducer/saveQuickSupportQuestionAnswerReducer'
import getQuickSupportQuestionAnswerDetail from './reducer/getQuickSupportQuestionAnswerDetailReducer'
import deleteQuickSupportQuestionAnswer from './reducer/deleteQuickSupportQuestionAnswerReducer'
import getModuleListByRole from './reducer/getModuleListByRoleReducer'
import getTestBookList from './reducer/getTestBookListReducer'
import deleteQuickSupportCategory from './reducer/deleteQuickSupportCategoryReducer'
import activateDoctor from './reducer/activateDoctorReducer'
import changeEmployeeStatus from './reducer/changeEmployeeStatusReducer'
import changeInstituteStatus from './reducer/changeInstituteStatusReducer'

import getMouList from './reducer/getMouListReducer'
import saveMou from './reducer/saveMouReducer'
import deleteMou from './reducer/deleteMouReducer'
import getContactUsEnquriesList from './reducer/getContactUsEnquriesListReducer'
import getContactUsEnquriesDetail from './reducer/getContactUsEnquriesDetailReducer'
import otp from './reducer/optReducer'
import resetPassword from './reducer/resetPasswordReducer'
import getLedgerAccountList from './reducer/getLedgerAccountListReducer'
import doctorSignupRequestList from './reducer/doctorSignupRequestListReducer'
import viewDoctorSignupRequest from './reducer/viewDoctorSignupRequestReducer'
import getDtrfsList from './reducer/getDtrfsListReducer'
import getSubGroupList from './reducer/getSubGroupListReducer'
import getEmployeeMaster from './reducer/getEmployeeMasterReducer'
import getPaymentHistoryList from './reducer/getPaymentHistoryListReducer'
import getInstituteTestPricingMasterInstitute from './reducer/getInstituteTestPricingMasterInstituteReducer'
import getNewDoctorList from './reducer/getNewDoctorListReducer';
import getNewInstituteList from './reducer/getNewInstituteListReducer';
import getCentreLocations from './reducer/getCentreLocationsReducer';
import getInstituteTestPricingUploadMaster from './reducer/getInstituteTestPricingUploadMasterReducer';
export default combineReducers({
    saveTestMaster,
    getListTestMaster,
    deleteTestMaster,
    viewTestMaster,
    saveInstitute,
    getInstituteList,
    getInstituteDetail,
    deleteInstitute,
    getDoctorList,
    deleteDoctor,
    getDoctorDetail,
    saveSample,
    getSampleList,
    getSampleDetail,
    deleteSample,
    saveDoctor,
    saveInstituteTestPricingMaster,
    getInstituteTestPricingMaster,
    deleteInstituteTestPricingMaster,
    getInstituteTestPricingDetail,
    saveArticle,
    getArticleList,
    deleteArticle,
    getArticleDetail,
    savePregnancyHighlights,
    getPregnancyHighlightsList,
    deletePregnancyHighlights,
    getPregnancyHighlightsDetail,
    login,
    logout,
    getCommonMasterList,
    saveCommonMaster,
    deleteCommonMaster,
    getPageContentList,
    savePageContent,
    getPageContentDetail,
    deletePageContent,
    getUserTermAndConditionList,
    getUserTermAndConditionDetail,
    saveSystemUser,
    systemUserList,
    systemUserDetail,
    saveUserRights,
    saveBulkUpload,
    getEmployeeList,
    getEmployeeDropdown,
    deleteEmployee,
    getEmployeeDetail,
    saveEmployee,
    getCouponList,
    deleteCoupon,
    getCouponDetail,
    saveCoupon,
    getTestimonialList,
    changeTestimonialStatus,
    getPincodeDetail,
    getPincodeList,
    getPatientList,
    getPatientDetail,
    savePatient,
    getConsulationDashboardList,
    getConsulationDashboardDetail,
    getPaymentHistory,
    getPaymentHistoryCoupon,
    getChatLog,
    saveContainer,
    getContainerList,
    getContainerDetail,
    deleteContainer,
    saveSampleContainerTestMap,
    getSampleContainerTestMapList,
    getSampleContainerTestMapDetail,
    deleteSampleContainerTestMap,
    getQuickSupportCategoryList,
    saveQuickSupportCategory,
    saveAdminType,
    getAdminTypeDetail,
    getAdminTypeList,
    deleteAdminType,
    saveRole,
    getRoleList,
    getRoleDetail,
    deleteRole,
    deleteModule,
    saveModule,
    getModuleList,
    getModuleDetail,
    saveRoleModuleMapping,
    getRoleModuleMappingList,
    getRoleModuleMappingDetail,
    deleteRoleModuleMapping,
    getRoleModuleMappingDetailByRole,
    getQuickSupportCategoryDetail,
    getQuickSupportQuestionAnswerList,
    saveQuickSupportQuestionAnswer,
    getQuickSupportQuestionAnswerDetail,
    deleteQuickSupportQuestionAnswer,
    getModuleListByRole,
    getTestBookList,
    deleteQuickSupportCategory,
    activateDoctor,
    changeEmployeeStatus,
    changeInstituteStatus,
    getMouList,
    saveMou,
    deleteMou,
    getContactUsEnquriesList,
    getContactUsEnquriesDetail,
    otp,
    resetPassword,
    getLedgerAccountList,
    doctorSignupRequestList,
    viewDoctorSignupRequest,
    getDtrfsList,
    getSubGroupList,
    getEmployeeMaster,
    getPaymentHistoryList,
    getInstituteTestPricingMasterInstitute,
    getNewDoctorList,
    getNewInstituteList,
    getCentreLocations,
    getInstituteTestPricingUploadMaster
})