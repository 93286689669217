export const GET_USER_TERM_AND_CONDITION_DETAIL = "GET_USER_TERM_AND_CONDITION_DETAIL"
export const GET_USER_TERM_AND_CONDITION_DETAIL_FAIL = "GET_USER_TERM_AND_CONDITION_DETAIL_FAIL"
export const GET_USER_TERM_AND_CONDITION_DETAIL_SUCCESS = "GET_USER_TERM_AND_CONDITION_DETAIL_SUCCESS"
export const GET_USER_TERM_AND_CONDITION_DETAIL_RESET = "GET_USER_TERM_AND_CONDITION_DETAIL_RESET"

export const getUserTermAndConditionDetail = (params) => {
    return { type: GET_USER_TERM_AND_CONDITION_DETAIL, params }
}
export const getUserTermAndConditionDetailFail = (response) => {
    return { type: GET_USER_TERM_AND_CONDITION_DETAIL_FAIL, response }
}
export const getUserTermAndConditionDetailSuccess = (response) => {
    return { type: GET_USER_TERM_AND_CONDITION_DETAIL_SUCCESS, response }
}
export const getUserTermAndConditionDetailReset = () => {
    return { type: GET_USER_TERM_AND_CONDITION_DETAIL_RESET }
}