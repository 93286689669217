
export const GET_NEW_INSTITUTE_LIST = "GET_NEW_INSTITUTE_LIST";
export const GET_NEW_INSTITUTE_LIST_SUCCESS = "GET_NEW_INSTITUTE_LIST_SUCCESS";
export const GET_NEW_INSTITUTE_LIST_FAIL = "GET_NEW_INSTITUTE_LIST_FAIL";
export const GET_NEW_INSTITUTE_LIST_RESET = 'GET_NEW_INSTITUTE_LIST_RESET';


export const getNewInstituteList = (params) => {
    return {
        type: GET_NEW_INSTITUTE_LIST,
        params,
    };
};

export const getNewInstituteListSuccess = (response) => {
    return {
        type: GET_NEW_INSTITUTE_LIST_SUCCESS,
        response,
    };
};

export const getNewInstituteListFail = (response) => {
    return {
        type: GET_NEW_INSTITUTE_LIST_FAIL,
        response,
    };
};

export const getNewInstituteListReset = () => {
	return {
		type: GET_NEW_INSTITUTE_LIST_RESET,
	};
};
