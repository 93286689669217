import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_DOCTOR,
    deleteDoctorFail,
    deleteDoctorSuccess
} from '../action/deleteDoctorAction'

// api 
import deleteDoctorApi from '../api/deleteDoctorApi'

export function* deleteDoctorSaga(action) {
    try {
        const response = yield call(() => deleteDoctorApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteDoctorSuccess(response, action))
        } else {
            yield put(deleteDoctorFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteDoctorFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_DOCTOR, deleteDoctorSaga)
}