export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE"
export const DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL"
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS"
export const DELETE_EMPLOYEE_RESET = "DELETE_EMPLOYEE_RESET"

export const deleteEmployee = (params) => {
    return { type: DELETE_EMPLOYEE, params }
}
export const deleteEmployeeFail = (response) => {
    return { type: DELETE_EMPLOYEE_FAIL, response }
}
export const deleteEmployeeSuccess = (response) => {
    return { type: DELETE_EMPLOYEE_SUCCESS, response }
}
export const deleteEmployeeReset = () => {
    return { type: DELETE_EMPLOYEE_RESET }
}