export const GET_PAGE_CONTENT_DETAIL = "GET_PAGE_CONTENT_DETAIL"
export const GET_PAGE_CONTENT_DETAIL_FAIL = "GET_PAGE_CONTENT_DETAIL_FAIL"
export const GET_PAGE_CONTENT_DETAIL_SUCCESS = "GET_PAGE_CONTENT_DETAIL_SUCCESS"
export const GET_PAGE_CONTENT_DETAIL_RESET = "GET_PAGE_CONTENT_DETAIL_RESET"

export const getPageContentDetail = (params) => {
    return { type: GET_PAGE_CONTENT_DETAIL, params }
}
export const getPageContentDetailFail = (response) => {
    return { type: GET_PAGE_CONTENT_DETAIL_FAIL, response }
}
export const getPageContentDetailSuccess = (response) => {
    return { type: GET_PAGE_CONTENT_DETAIL_SUCCESS, response }
}
export const getPageContentDetailReset = () => {
    return { type: GET_PAGE_CONTENT_DETAIL_RESET }
}