export const CHANGE_EMPLOYEE_STATUS = "CHANGE_EMPLOYEE_STATUS"
export const CHANGE_EMPLOYEE_STATUS_FAIL = "CHANGE_EMPLOYEE_STATUS_FAIL"
export const CHANGE_EMPLOYEE_STATUS_SUCCESS = "CHANGE_EMPLOYEE_STATUS_SUCCESS"
export const CHANGE_EMPLOYEE_STATUS_RESET = "CHANGE_EMPLOYEE_STATUS_RESET"

export const changeEmployeeStatus = (params) => {
    return { type: CHANGE_EMPLOYEE_STATUS, params }
}
export const changeEmployeeStatusFail = (response) => {
    return { type: CHANGE_EMPLOYEE_STATUS_FAIL, response }
}
export const changeEmployeeStatusSuccess = (response) => {
    return { type: CHANGE_EMPLOYEE_STATUS_SUCCESS, response }
}
export const changeEmployeeStatusReset = () => {
    return { type: CHANGE_EMPLOYEE_STATUS_RESET }
}