export const GET_CONTACT_US_ENQURIES_LIST = "GET_CONTACT_US_ENQURIES_LIST"
export const GET_CONTACT_US_ENQURIES_LIST_FAIL = "GET_CONTACT_US_ENQURIES_LIST_FAIL"
export const GET_CONTACT_US_ENQURIES_LIST_SUCCESS = "GET_CONTACT_US_ENQURIES_LIST_SUCCESS"
export const GET_CONTACT_US_ENQURIES_LIST_RESET = "GET_CONTACT_US_ENQURIES_LIST_RESET"

export const getContactUsEnquriesList = (params) => {
    return { type: GET_CONTACT_US_ENQURIES_LIST, params }
}
export const getContactUsEnquriesListFail = (response) => {
    return { type: GET_CONTACT_US_ENQURIES_LIST_FAIL, response }
}
export const getContactUsEnquriesListSuccess = (response) => {
    return { type: GET_CONTACT_US_ENQURIES_LIST_SUCCESS, response }
}
export const getContactUsEnquriesListReset = () => {
    return { type: GET_CONTACT_US_ENQURIES_LIST_RESET }
}