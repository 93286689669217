export const GET_PAYMENT_HISTORY_COUPON = "GET_PAYMENT_HISTORY_COUPON"
export const GET_PAYMENT_HISTORY_COUPON_FAIL = "GET_PAYMENT_HISTORY_COUPON_FAIL"
export const GET_PAYMENT_HISTORY_COUPON_SUCCESS = "GET_PAYMENT_HISTORY_COUPON_SUCCESS"
export const GET_PAYMENT_HISTORY_COUPON_RESET = "GET_PAYMENT_HISTORY_COUPON_RESET"

export const getPaymentHistoryCoupon = (params) => {
    return { type: GET_PAYMENT_HISTORY_COUPON, params }
}
export const getPaymentHistoryCouponFail = (response) => {
    return { type: GET_PAYMENT_HISTORY_COUPON_FAIL, response }
}
export const getPaymentHistoryCouponSuccess = (response) => {
    return { type: GET_PAYMENT_HISTORY_COUPON_SUCCESS, response }
}
export const getPaymentHistoryCouponReset = () => {
    return { type: GET_PAYMENT_HISTORY_COUPON_RESET }
}