// action
import {
    GET_MODULE_LIST_BY_ROLE,
    GET_MODULE_LIST_BY_ROLE_FAIL,
    GET_MODULE_LIST_BY_ROLE_SUCCESS,
    GET_MODULE_LIST_BY_ROLE_RESET
} from '../action/getModuleListByRoleAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    list: [],
    admin_type_name: "",
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MODULE_LIST_BY_ROLE:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_MODULE_LIST_BY_ROLE_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_MODULE_LIST_BY_ROLE_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data.data.result.admin_type_id.module_id,
                admin_type_name: action.response.data.data.result.admin_type_id.name,
            }
        case GET_MODULE_LIST_BY_ROLE_RESET:
            return initialState
        default:
            return state
    }
}