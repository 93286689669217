// action
import {
    DELETE_TEST_MASTER,
    DELETE_TEST_MASTER_FAIL,
    DELETE_TEST_MASTER_SUCCESS,
    DELETE_TEST_MASTER_RESET
} from '../action/deleteTestMasterAction'

// initialState
const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DELETE_TEST_MASTER:
            return {
                ...state,
                apiState: "loading"
            }
        case DELETE_TEST_MASTER_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case DELETE_TEST_MASTER_SUCCESS:
            return {
                ...state,
                apiState: "success"
            }
        case DELETE_TEST_MASTER_RESET:
            return initialState
        default:
            return state
    }
}