export const GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL = "GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL"
export const GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_FAIL = "GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_FAIL"
export const GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_SUCCESS = "GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_SUCCESS"
export const GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_RESET = "GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_RESET"

export const getQuickSupportQuestionAnswerDetail = (params) => {
    return { type: GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL, params }
}
export const getQuickSupportQuestionAnswerDetailFail = (response) => {
    return { type: GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_FAIL, response }
}
export const getQuickSupportQuestionAnswerDetailSuccess = (response) => {
    return { type: GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_SUCCESS, response }
}
export const getQuickSupportQuestionAnswerDetailReset = () => {
    return { type: GET_QUICK_SUPPORT_QUESTION_ANSWER_DETAIL_RESET }
}