export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL"
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS"
export const DELETE_ROLE_RESET = "DELETE_ROLE_RESET"

export const deleteRole = (params) => {
    return { type: DELETE_ROLE, params }
}
export const deleteRoleFail = (response) => {
    return { type: DELETE_ROLE_FAIL, response }
}
export const deleteRoleSuccess = (response) => {
    return { type: DELETE_ROLE_SUCCESS, response }
}
export const deleteRoleReset = () => {
    return { type: DELETE_ROLE_RESET }
}