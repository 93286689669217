export const DELETE_COUPON = "DELETE_COUPON"
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL"
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS"
export const DELETE_COUPON_RESET = "DELETE_COUPON_RESET"

export const deleteCoupon = (params) => {
    return { type: DELETE_COUPON, params }
}
export const deleteCouponFail = (response) => {
    return { type: DELETE_COUPON_FAIL, response }
}
export const deleteCouponSuccess = (response) => {
    return { type: DELETE_COUPON_SUCCESS, response }
}
export const deleteCouponReset = () => {
    return { type: DELETE_COUPON_RESET }
}