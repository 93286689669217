export const SAVE_BULK_UPLOAD = "SAVE_BULK_UPLOAD"
export const SAVE_BULK_UPLOAD_FAIL = "SAVE_BULK_UPLOAD_FAIL"
export const SAVE_BULK_UPLOAD_SUCCESS = "SAVE_BULK_UPLOAD_SUCCESS"
export const SAVE_BULK_UPLOAD_RESET = "SAVE_BULK_UPLOAD_RESET"

export const saveBulkUpload = (params, apiUrl) => {
    return { type: SAVE_BULK_UPLOAD, params, apiUrl }
}
export const saveBulkUploadFail = (response) => {
    return { type: SAVE_BULK_UPLOAD_FAIL, response }
}
export const saveBulkUploadSuccess = (response) => {
    return { type: SAVE_BULK_UPLOAD_SUCCESS, response }
}
export const saveBulkUploadReset = () => {
    return { type: SAVE_BULK_UPLOAD_RESET }
}