import moment from "moment"
import config from "./config"
import _ from "lodash"

export const dateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY")
}

export const dateTimeFormat = (date) => {
    return moment(date).format("DD/MM/YYYY hh:mm A")
}
export const dateTimeUTCFormat = (date) => {
    return moment(date).utc().format("DD/MM/YYYY hh:mm A")
}

export const dateFormatMonthName = (date) => {
    return moment(date).format("DD MMM YYYY")
}

export const dateTimeFormatMonthName = (date) => {
    return moment(date).format("DD MMM YYYY hh:mm A")
}

export const timeSlotFormat = (time) => {
    return moment(time, "HH:mm:ss").format("hh:mm A")
}

export const isLogin = () => {
    const LilacUserToken = localStorage.getItem('LilacUserToken')

    if (LilacUserToken) return true
    else return false
}

export const getUserToken = () => {
    return localStorage.getItem('LilacUserToken') || null
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem('LilacUser')) || null
}

export const getUserCommonRights = () => {
    return localStorage.getItem('LilacRoleRights') === "undefined" ? null : JSON.parse(localStorage.getItem('LilacRoleRights')) || null
}

export const checkRights = (module, sub_module) => {
    let user = getUser();
    if (user && user.role.name === 'Admin') return true

    let rights = user && user.rights || {}

    if (!_.isEmpty(rights)) {
        return (rights[module] && _.some(rights[module], el => el === sub_module))
    } else {
        let getCommonRights = getUserCommonRights() || [];
        return (getCommonRights[module] && _.some(getCommonRights[module], el => el === sub_module))
    }
}

export const checkRightsForMainModule = (module_list) => {
    let user = getUser();
    if (user && user.role.name === 'Admin') return true
    let rights = user && user.rights || {}
    for (let i = 0; i < module_list.length; i++) {
        const module = module_list[i];
        if (!_.isEmpty(rights)) {
            if (rights[module]) {
                return true
                break
            }
        } else {
            let getCommonRights = getUserCommonRights() || {};
            if (getCommonRights[module] && getCommonRights[module].length > 0) {
                return true
                break
            }
        }
    }
    return false
}

export const clearAuthData = () => {
    localStorage.removeItem('LilacUserToken')
    localStorage.removeItem('LilacUser')
    localStorage.removeItem('LilacRoleRights')
}

export const s3_cdn = () => {
    return `https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/`
}
export const capFirstLt = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const validateImageFile = (file) => {
    let ext
    if (file.type == "image/jpeg") ext = "jpeg"
    if (file.type == "image/png") ext = "png"
    if (file.type == "text/csv") ext = ".csv"
    if (file.type == "text/xls") ext = ".xls"
    if (file.type == "text/xlsx") ext = ".xlsx"
    if (ext) {
        return { status: true, ext: ext }
    } else {
        return { status: false }
    }
}
export const validateImageFileMou = (file) => {
    let ext
    if (file.type == "image/jpeg") ext = "jpeg"
    if (file.type == "image/png") ext = "png"
    if (file.type == "text/csv") ext = ".csv"
    if (file.type == "text/xls") ext = ".xls"
    if (file.type == "text/xlsx") ext = ".xlsx"
    if (file.type == "application/pdf") ext = ".pdf"
    if (ext) {
        return { status: true, ext: ext }
    } else {
        return { status: false }
    }
}
export const validateAudioFile = (file) => {
    console.log(file, typeof file);
    let ext
    if (file.type == "audio/mp3") ext = "mp3"
    if (file.type == "audio/mpeg") ext = "mp3"

    if (ext) {
        return { status: true, ext: ext }
    } else {
        return { status: false }
    }
}

export const validateVideoFile = (file) => {
    let ext = ""
    if (file.type == "video/mp4") ext = "mp4"
        // if (file.type == "audio/mp4") ext = "mp4"
        // if (file.type == "audio/ogg") ext = "ogg"

    if (ext) {
        return { status: true, ext: ext }
    } else {
        return { status: false }
    }
}


export const getS3SingedUrl = async(filename, ext, file, foldername) => {
    return new Promise((resolve, reject) => {
        fetch(`${config.api.base_url}s3/get-singed-url?filename=${filename}&ext=${ext}&folder_name=${foldername}`, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                // "authorization": `Bearer ${getUserToken()}`
            }
        }).then(res => {
            const result = res.json();
            return Promise.all([result]);
        }).then((result) => {
            resolve({
                ['url']: result[0].url,
                ['filename']: `${filename}.${ext}`
            })
        }).catch(console.log)
    })
}


export const handleHttpErr = (response) => {
    if (response.status === 403) {
        clearAuthData()
        window.location.reload()
    }
}

export const tinyEditor = () => {
    // return "o90kand2xuso6p9jrse3ifsbflbynpumh4livdoxkqsb9nd6"
    // return "hhbs3i3h9g51ymno02tfeui4aga05am2c0o4crmjumao74eh" //amir key
    return "akiwbjtq1n2d4wiyj6zjdib5vn30eufc242ujc3dk70sow6c" 
}

export const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const instituteMasterHeaders = ['LIDP ID#', 'LIMS INSTITUTE ID#', 'Title', 'Name of the Institute', 'Official Mobile',
    'Official Email ID', 'Address', 'Address_2', 'City', 'District', 'State', 'Pincode', 'Collection Centre',
    'Clinic timings', 'Work Days', 'Category of Institute', 'Status of Institute', 'Institute Login Id', 
    'Institute Onboarded On', 'Institute Business Type', 'Report Release Scenario', 
    'Report Hard Copy Delivery Alert Mobile Number', 'Report E-mail CC', 'Report E-mail BCC', 'Report SMS Details To', 
    'Sample Acknowledgement Email', 'PCPNDT Expiration', 'Finance Person First Name', 'Finance Person Last Name', 
    'Finance Person Mobile Number', 'Finance E-mail', 'Finance E-mail CC', 'Finance E-mail BCC', 'Financial SMS Details To', 
    'Pick Up Partner', 'Pickup Partner Branch', 'Pick Up Partner Area Code', 'Transit Hours From Institute to LCP', 
    'Partner Pick Up Cut Off', 'Mandatory Pick Up Info to Pick-up Partner', 'Pick Up Timimg Slots', 'Report Dispatch Partner', 
    'Expiry Date of Category', 'Block Account', 'Block by', 'Block Date', 'Doctors Attached Id#', 'Credit term', 
    'Accounting Institute Type', 'Billing Account', 'Account Receivable', 'Ledger Account', 'General Ledger Account Code', 
    'Billing Account Type', 'Rate at Booking', 'TDS Applicable', 'TDS %', 'Billing Cycle', 'Billing Category', 'Receipt Mode', 
    'Payment Mode', 'Credit Limit', 'Pancard number', 'GST number', 'TAN number', 'Bank A/c Name', 'Bank A/c Number', 'IFSC Code', 
    'Branch', 'PCPNDT Registration', 'PCPNDT Expiration', 'HQ/ Primary Marketing Location', 'BDM ID', 'Reporting Manager', 
    'Reporting Head', 'MOU Done', 'MOU Validity', 'Key Decision Maker Name', 'Key Decision Maker Mobile Num', 
    'Key Decision Maker Email Id', 'Expected Minimum business per month', 'Expected Maximum business per month', 
    'Total Patient Footfall', '# of Pregnancies', 'Billing Category Id', 'Server', 'Location', 'Is New Institute', 'Super DTRF ID'];

export const doctorMasterHeaders = ['LIDP ID#', 'User ID', 'LIMS DR ID#', 'Title', 'Pronouns', 'First Name',
    'Middle Name', 'Last Name', 'Status', 'DOB', 'Gender', 'Email ID', 'Mobile number Country Code',
    'Mobile number', 'WhatsApp No Country Code', 'WhatsApp Num', 'Home Landline Country Code',
    'Home Landline State Code', 'Home Landline', 'Address', 'City', 'District', 'State', 'Pincode',
    'Aadhar Number', 'Pan Number', 'FB ID', 'Insta ID', 'LinkedIn ID', 'Interests', 
    'Date of Marriage Anniversary', 'Preferred Mode \n of Contact', 'Hospitals Attached ID#', 
    'Speciality ID#', 'Sonographer', 'NT Status for Reporting', 'Hospital Visit/Meeting Timings',
    'FMF Code', 'FMF Certified For', 'FMF License Validity', 'PNDT Registration', 
    'PNDT Registration Validity From', 'PNDT Registration Validity To', 'MCI Registration Number',
    'Is New Doctor', 'Super DTRF ID'];