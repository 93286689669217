import { call, put, takeLatest } from 'redux-saga/effects'

/* OTHERS */
import { handleHttpErr } from "../utils"

// action
import {
    VIEW_TEST_MASTER,
    viewTestMasterFail,
    viewTestMasterSuccess
} from '../action/viewTestMasterAction'

// api
import viewTestMasterApi from '../api/viewTestMasterApi'

export function* viewTestMasterSaga(action) {
    try {
        const response = yield call(() => viewTestMasterApi(action.params))
        if (response) {
            yield put(viewTestMasterSuccess(response, action))
        }
    }
    catch (e) {
        handleHttpErr(e.response)
        yield put(viewTestMasterFail(e.response, action))
    }
}
export default function* mySaga() {
    yield takeLatest(VIEW_TEST_MASTER, viewTestMasterSaga)
}