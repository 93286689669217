// action
import {
    GET_LIST_TEST_MASTER,
    GET_LIST_TEST_MASTER_FAIL,
    GET_LIST_TEST_MASTER_SUCCESS,
    GET_LIST_TEST_MASTER_RESET
} from '../action/getListTestMasterActions'

// initialState
const initialState = {
    apiState: "",
    message: "",
    list: [],
    pagination: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LIST_TEST_MASTER:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_LIST_TEST_MASTER_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response.data.message
            }
        case GET_LIST_TEST_MASTER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data.result,
                pagination: action.response.data.pagination
            }
        case GET_LIST_TEST_MASTER_RESET:
            return initialState

        default:
            return state
    }
}