// action
import {
    SAVE_MOU,
   SAVE_MOU_FAIL,
    SAVE_MOU_SUCCESS,
    SAVE_MOU_RESET
} from '../action/saveMouAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_MOU:
            return {
                ...state,
                apiState: "loading"
            }
        case SAVE_MOU_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case SAVE_MOU_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
            }
        case SAVE_MOU_RESET:
            return initialState
        default:
            return state
    }
}