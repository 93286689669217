export const GET_LEDGER_ACCOUNT_LIST = "GET_LEDGER_ACCOUNT_LIST"
export const GET_LEDGER_ACCOUNT_LIST_FAIL = "GET_LEDGER_ACCOUNT_LIST_FAIL"
export const GET_LEDGER_ACCOUNT_LIST_SUCCESS = "GET_LEDGER_ACCOUNT_LIST_SUCCESS"
export const GET_LEDGER_ACCOUNT_LIST_RESET = "GET_LEDGER_ACCOUNT_LIST_RESET"

export const getLedgerAccountList = (params) => {
    return { type: GET_LEDGER_ACCOUNT_LIST, params }
}
export const getLedgerAccountListFail = (response) => {
    return { type: GET_LEDGER_ACCOUNT_LIST_FAIL, response }
}
export const getLedgerAccountListSuccess = (response) => {
    return { type: GET_LEDGER_ACCOUNT_LIST_SUCCESS, response }
}
export const getLedgerAccountListReset = () => {
    return { type: GET_LEDGER_ACCOUNT_LIST_RESET }
}