// action
import {
    VIEW_DOCTOR_SIGNUP_REQUEST,
    VIEW_DOCTOR_SIGNUP_REQUEST_FAIL,
    VIEW_DOCTOR_SIGNUP_REQUEST_SUCCESS,
    VIEW_DOCTOR_SIGNUP_REQUEST_RESET
} from '../action/viewDoctorSignupRequestAction'

// initialState
const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case VIEW_DOCTOR_SIGNUP_REQUEST:
            return {
                ...state,
                apiState: "loading"
            }
        case VIEW_DOCTOR_SIGNUP_REQUEST_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case VIEW_DOCTOR_SIGNUP_REQUEST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            }
        case VIEW_DOCTOR_SIGNUP_REQUEST_RESET:
            return initialState
        default:
            return state
    }
}