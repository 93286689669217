export const DELETE_DOCTOR = "DELETE_DOCTOR"
export const DELETE_DOCTOR_FAIL = "DELETE_DOCTOR_FAIL"
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS"
export const DELETE_DOCTOR_RESET = "DELETE_DOCTOR_RESET"

export const deleteDoctor = (params) => {
    return { type: DELETE_DOCTOR, params }
}
export const deleteDoctorFail = (response) => {
    return { type: DELETE_DOCTOR_FAIL, response }
}
export const deleteDoctorSuccess = (response) => {
    return { type: DELETE_DOCTOR_SUCCESS, response }
}
export const deleteDoctorReset = () => {
    return { type: DELETE_DOCTOR_RESET }
}