export const GET_ADMIN_TYPE_LIST = "GET_ADMIN_TYPE_LIST"
export const GET_ADMIN_TYPE_LIST_FAIL = "GET_ADMIN_TYPE_LIST_FAIL"
export const GET_ADMIN_TYPE_LIST_SUCCESS = "GET_ADMIN_TYPE_LIST_SUCCESS"
export const GET_ADMIN_TYPE_LIST_RESET = "GET_ADMIN_TYPE_LIST_RESET"

export const getAdminTypeList = (params) => {
    return { type: GET_ADMIN_TYPE_LIST, params }
}
export const getAdminTypeListFail = (response) => {
    return { type: GET_ADMIN_TYPE_LIST_FAIL, response }
}
export const getAdminTypeListSuccess = (response) => {
    return { type: GET_ADMIN_TYPE_LIST_SUCCESS, response }
}
export const getAdminTypeListReset = () => {
    return { type: GET_ADMIN_TYPE_LIST_RESET }
}