export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const logout = (params) => {
    return { type: LOGOUT, params }
}

export const logoutSuccess = (response) => {
    return { type: LOGOUT_SUCCESS, response }
}
export const logoutFail = (response) => {
    return { type: LOGOUT_FAIL, response }
}