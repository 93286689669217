export const GET_MODULE_LIST_BY_ROLE = "GET_MODULE_LIST_BY_ROLE"
export const GET_MODULE_LIST_BY_ROLE_FAIL = "GET_MODULE_LIST_BY_ROLE_FAIL"
export const GET_MODULE_LIST_BY_ROLE_SUCCESS = "GET_MODULE_LIST_BY_ROLE_SUCCESS"
export const GET_MODULE_LIST_BY_ROLE_RESET = "GET_MODULE_LIST_BY_ROLE_RESET"

export const getModuleListByRole = (params) => {
    return { type: GET_MODULE_LIST_BY_ROLE, params }
}
export const getModuleListByRoleFail = (response) => {
    return { type: GET_MODULE_LIST_BY_ROLE_FAIL, response }
}
export const getModuleListByRoleSuccess = (response) => {
    return { type: GET_MODULE_LIST_BY_ROLE_SUCCESS, response }
}
export const getModuleListByRoleReset = () => {
    return { type: GET_MODULE_LIST_BY_ROLE_RESET }
}