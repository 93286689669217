// action
import {
    VIEW_TEST_MASTER,
    VIEW_TEST_MASTER_FAIL,
    VIEW_TEST_MASTER_SUCCESS,
    VIEW_TEST_MASTER_RESET
} from '../action/viewTestMasterAction'
import { api_err_msg } from "../strings.json"


// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

export default function (state = initialState, action) {

    switch (action.type) {
        case VIEW_TEST_MASTER:
            return {
                ...state,
                apiState: "loading"
            }
        case VIEW_TEST_MASTER_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case VIEW_TEST_MASTER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.result,
            }
        case VIEW_TEST_MASTER_RESET:
            return initialState

        default:
            return state
    }
}