export const OTP = "OTP"
export const OTP_FAIL = "OTP_FAIL"
export const OTP_SUCCESS = "OTP_SUCCESS"
export const OTP_RESET = "OTP_RESET"

export const otp = (params) => {
    return { type: OTP, params }
}
export const otpFail = (response) => {
    return { type: OTP_FAIL, response }
}
export const otpSuccess = (response) => {
    return { type: OTP_SUCCESS, response }
}
export const otpReset = () => {
    return { type: OTP_RESET }
}