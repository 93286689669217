// action
import {
    DELETE_QUICK_SUPPORT_QUESTION_ANSWER,
    DELETE_QUICK_SUPPORT_QUESTION_ANSWER_FAIL,
    DELETE_QUICK_SUPPORT_QUESTION_ANSWER_SUCCESS,
    DELETE_QUICK_SUPPORT_QUESTION_ANSWER_RESET
} from '../action/deleteQuickSupportQuestionAnswerAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DELETE_QUICK_SUPPORT_QUESTION_ANSWER:
            return {
                ...state,
                apiState: "loading"
            }
        case DELETE_QUICK_SUPPORT_QUESTION_ANSWER_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case DELETE_QUICK_SUPPORT_QUESTION_ANSWER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
            }
        case DELETE_QUICK_SUPPORT_QUESTION_ANSWER_RESET:
            return initialState
        default:
            return state
    }
}