// action
import {
    SAVE_COMMON_MASTER,
    SAVE_COMMON_MASTER_FAIL,
    SAVE_COMMON_MASTER_SUCCESS,
    SAVE_COMMON_MASTER_RESET
} from '../action/saveCommonMasterAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_COMMON_MASTER:
            return {
                ...state,
                apiState: "loading"
            }
        case SAVE_COMMON_MASTER_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case SAVE_COMMON_MASTER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
            }
        case SAVE_COMMON_MASTER_RESET:
            return initialState
        default:
            return state
    }
}