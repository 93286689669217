export const GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE = "GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE"
export const GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_FAIL = "GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_FAIL"
export const GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_SUCCESS = "GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_SUCCESS"
export const GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_RESET = "GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_RESET"

export const getRoleModuleMappingDetailByRole = (params) => {
    return { type: GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE, params }
}
export const getRoleModuleMappingDetailByRoleFail = (response) => {
    return { type: GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_FAIL, response }
}
export const getRoleModuleMappingDetailByRoleSuccess = (response) => {
    return { type: GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_SUCCESS, response }
}
export const getRoleModuleMappingDetailByRoleReset = () => {
    return { type: GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE_RESET }
}