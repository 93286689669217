
export const DELETE_INSTITUTE_TEST_PRICING_MASTER = "DELETE_INSTITUTE_TEST_PRICING_MASTER"
export const DELETE_INSTITUTE_TEST_PRICING_MASTER_FAIL = "DELETE_INSTITUTE_TEST_PRICING_MASTER_FAIL"
export const DELETE_INSTITUTE_TEST_PRICING_MASTER_SUCCESS = "DELETE_INSTITUTE_TEST_PRICING_MASTER_SUCCESS"
export const DELETE_INSTITUTE_TEST_PRICING_MASTER_RESET = "DELETE_INSTITUTE_TEST_PRICING_MASTER_RESET"

export const deleteInstituteTestPricingMaster = (params) => {
    return { type: DELETE_INSTITUTE_TEST_PRICING_MASTER, params }
}
export const deleteInstituteTestPricingMasterFail = (response) => {
    return { type: DELETE_INSTITUTE_TEST_PRICING_MASTER_FAIL, response }
}
export const deleteInstituteTestPricingMasterSuccess = (response) => {
    return { type: DELETE_INSTITUTE_TEST_PRICING_MASTER_SUCCESS, response }
}
export const deleteInstituteTestPricingMasterReset = () => {
    return { type: DELETE_INSTITUTE_TEST_PRICING_MASTER_RESET }
}