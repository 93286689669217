export const GET_ROLE_LIST = "GET_ROLE_LIST"
export const GET_ROLE_LIST_FAIL = "GET_ROLE_LIST_FAIL"
export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS"
export const GET_ROLE_LIST_RESET = "GET_ROLE_LIST_RESET"

export const getRoleList = (params) => {
    return { type: GET_ROLE_LIST, params }
}
export const getRoleListFail = (response) => {
    return { type: GET_ROLE_LIST_FAIL, response }
}
export const getRoleListSuccess = (response) => {
    return { type: GET_ROLE_LIST_SUCCESS, response }
}
export const getRoleListReset = () => {
    return { type: GET_ROLE_LIST_RESET }
}