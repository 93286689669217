export const GET_CENTRE_LOCATIONS = "GET_CENTRE_LOCATIONS"
export const GET_CENTRE_LOCATIONS_FAIL = "GET_CENTRE_LOCATIONS_FAIL"
export const GET_CENTRE_LOCATIONS_SUCCESS = "GET_CENTRE_LOCATIONS_SUCCESS"
export const GET_CENTRE_LOCATIONS_RESET = "GET_CENTRE_LOCATIONS_RESET"

export const getCentreLocations = (params) => {
    return { type: GET_CENTRE_LOCATIONS, params }
}
export const getCentreLocationsFail = (response) => {
    return { type: GET_CENTRE_LOCATIONS_FAIL, response }
}
export const getCentreLocationsSuccess = (response) => {
    return { type: GET_CENTRE_LOCATIONS_SUCCESS, response }
}
export const getCentreLocationsReset = () => {
    return { type: GET_CENTRE_LOCATIONS_RESET }
}