export const GET_EMPLOYEE_MASTER = "GET_EMPLOYEE_MASTER"
export const GET_EMPLOYEE_MASTER_FAIL = "GET_EMPLOYEE_MASTER_FAIL"
export const GET_EMPLOYEE_MASTER_SUCCESS = "GET_EMPLOYEE_MASTER_SUCCESS"
export const GET_EMPLOYEE_MASTER_RESET = "GET_EMPLOYEE_MASTER_RESET"

export const getEmployeeMaster = (params) => {
    return { type: GET_EMPLOYEE_MASTER, params }
}
export const getEmployeeMasterFail = (response) => {
    return { type: GET_EMPLOYEE_MASTER_FAIL, response }
}
export const getEmployeeMasterSuccess = (response) => {
    return { type: GET_EMPLOYEE_MASTER_SUCCESS, response }
}
export const getEmployeeMasterReset = () => {
    return { type: GET_EMPLOYEE_MASTER_RESET }
}