export const GET_INSTITUTE_DETAIL = "GET_INSTITUTE_DETAIL"
export const GET_INSTITUTE_DETAIL_FAIL = "GET_INSTITUTE_DETAIL_FAIL"
export const GET_INSTITUTE_DETAIL_SUCCESS = "GET_INSTITUTE_DETAIL_SUCCESS"
export const GET_INSTITUTE_DETAIL_RESET = "GET_INSTITUTE_DETAIL_RESET"

export const getInstituteDetail = (params) => {
    return { type: GET_INSTITUTE_DETAIL, params }
}
export const getInstituteDetailFail = (response) => {
    return { type: GET_INSTITUTE_DETAIL_FAIL, response }
}
export const getInstituteDetailSuccess = (response) => {
    return { type: GET_INSTITUTE_DETAIL_SUCCESS, response }
}
export const getInstituteDetailReset = () => {
    return { type: GET_INSTITUTE_DETAIL_RESET }
}