export const SAVE_COMMON_MASTER = "SAVE_COMMON_MASTER"
export const SAVE_COMMON_MASTER_FAIL = "SAVE_COMMON_MASTER_FAIL"
export const SAVE_COMMON_MASTER_SUCCESS = "SAVE_COMMON_MASTER_SUCCESS"
export const SAVE_COMMON_MASTER_RESET = "SAVE_COMMON_MASTER_RESET"

export const saveCommonMaster = (params) => {
    return { type: SAVE_COMMON_MASTER, params }
}
export const saveCommonMasterFail = (response) => {
    return { type: SAVE_COMMON_MASTER_FAIL, response }
}
export const saveCommonMasterSuccess = (response) => {
    return { type: SAVE_COMMON_MASTER_SUCCESS, response }
}
export const saveCommonMasterReset = () => {
    return { type: SAVE_COMMON_MASTER_RESET }
}