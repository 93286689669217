export const GET_DOCTOR_DETAIL = "GET_DOCTOR_DETAIL"
export const GET_DOCTOR_DETAIL_FAIL = "GET_DOCTOR_DETAIL_FAIL"
export const GET_DOCTOR_DETAIL_SUCCESS = "GET_DOCTOR_DETAIL_SUCCESS"
export const GET_DOCTOR_DETAIL_RESET = "GET_DOCTOR_DETAIL_RESET"

export const getDoctorDetail = (params) => {
    return { type: GET_DOCTOR_DETAIL, params }
}
export const getDoctorDetailFail = (response) => {
    return { type: GET_DOCTOR_DETAIL_FAIL, response }
}
export const getDoctorDetailSuccess = (response) => {
    return { type: GET_DOCTOR_DETAIL_SUCCESS, response }
}
export const getDoctorDetailReset = () => {
    return { type: GET_DOCTOR_DETAIL_RESET }
}