import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_INSTITUTE,
    deleteInstituteFail,
    deleteInstituteSuccess
} from '../action/deleteInstituteAction'

// api 
import deleteInstituteApi from '../api/deleteInstituteApi'

export function* deleteInstituteSaga(action) {
    try {
        const response = yield call(() => deleteInstituteApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteInstituteSuccess(response, action))
        } else {
            yield put(deleteInstituteFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteInstituteFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_INSTITUTE, deleteInstituteSaga)
}