export const GET_PAGE_CONTENT_LIST = "GET_PAGE_CONTENT_LIST"
export const GET_PAGE_CONTENT_LIST_FAIL = "GET_PAGE_CONTENT_LIST_FAIL"
export const GET_PAGE_CONTENT_LIST_SUCCESS = "GET_PAGE_CONTENT_LIST_SUCCESS"
export const GET_PAGE_CONTENT_LIST_RESET = "GET_PAGE_CONTENT_LIST_RESET"

export const getPageContentList = (params) => {
    return { type: GET_PAGE_CONTENT_LIST, params }
}
export const getPageContentListFail = (response) => {
    return { type: GET_PAGE_CONTENT_LIST_FAIL, response }
}
export const getPageContentListSuccess = (response) => {
    return { type: GET_PAGE_CONTENT_LIST_SUCCESS, response }
}
export const getPageContentListReset = () => {
    return { type: GET_PAGE_CONTENT_LIST_RESET }
}