// action
import {
    SAVE_SYSTEM_USER,
    SAVE_SYSTEM_USER_FAIL,
    SAVE_SYSTEM_USER_SUCCESS,
    SAVE_SYSTEM_USER_RESET
} from '../action/saveSystemUsersAction'

// initialState
const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_SYSTEM_USER:
            return {
                ...state,
                apiState: "loading"
            }
        case SAVE_SYSTEM_USER_FAIL:
            return {
                ...state,
                apiState: "error",
                data: action.response.data.error
            }
        case SAVE_SYSTEM_USER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            }
        case SAVE_SYSTEM_USER_RESET:
            return initialState
        default:
            return state
    }
}