export const GET_MOU_LIST = "GET_MOU_LIST"
export const GET_MOU_LIST_FAIL = "GET_MOU_LIST_FAIL"
export const GET_MOU_LIST_SUCCESS = "GET_MOU_LIST_SUCCESS"
export const GET_MOU_LIST_RESET = "GET_MOU_LIST_RESET"

export const getMouList = (params) => {
    return { type: GET_MOU_LIST, params }
}
export const getMouListFail = (response) => {
    return { type: GET_MOU_LIST_FAIL, response }
}
export const getMouListSuccess = (response) => {
    return { type: GET_MOU_LIST_SUCCESS, response }
}
export const getMouListReset = () => {
    return { type: GET_MOU_LIST_RESET }
}