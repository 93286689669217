import axios from 'axios'
import config from '../config'
import { getUserToken } from "../utils"

const deleteTestMasterApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}test-master`,
        method: "delete",
        data: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getUserToken()}`
        }
    })
    return response.data
}

export default deleteTestMasterApi