// action
import {
    GET_INSTITUTE_LIST,
    GET_INSTITUTE_LIST_FAIL,
    GET_INSTITUTE_LIST_SUCCESS,
    GET_INSTITUTE_LIST_RESET
} from '../action/getInstituteListAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    list: [],
    pagination: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_INSTITUTE_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_INSTITUTE_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_INSTITUTE_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data.data.result,
                pagination: action.response.data.data.pagination,
            }
        case GET_INSTITUTE_LIST_RESET:
            return initialState
        default:
            return state
    }
}