export const GET_COUPON_LIST = "GET_COUPON_LIST"
export const GET_COUPON_LIST_FAIL = "GET_COUPON_LIST_FAIL"
export const GET_COUPON_LIST_SUCCESS = "GET_COUPON_LIST_SUCCESS"
export const GET_COUPON_LIST_RESET = "GET_COUPON_LIST_RESET"

export const getCouponList = (params) => {
    return { type: GET_COUPON_LIST, params }
}
export const getCouponListFail = (response) => {
    return { type: GET_COUPON_LIST_FAIL, response }
}
export const getCouponListSuccess = (response) => {
    return { type: GET_COUPON_LIST_SUCCESS, response }
}
export const getCouponListReset = () => {
    return { type: GET_COUPON_LIST_RESET }
}