export const GET_CONSULATION_DASHBOARD_DETAIL = "GET_CONSULATION_DASHBOARD_DETAIL"
export const GET_CONSULATION_DASHBOARD_DETAIL_FAIL = "GET_CONSULATION_DASHBOARD_DETAIL_FAIL"
export const GET_CONSULATION_DASHBOARD_DETAIL_SUCCESS = "GET_CONSULATION_DASHBOARD_DETAIL_SUCCESS"
export const GET_CONSULATION_DASHBOARD_DETAIL_RESET = "GET_CONSULATION_DASHBOARD_DETAIL_RESET"

export const getConsulationDashboardDetail = (params) => {
    return { type: GET_CONSULATION_DASHBOARD_DETAIL, params }
}
export const getConsulationDashboardDetailFail = (response) => {
    return { type: GET_CONSULATION_DASHBOARD_DETAIL_FAIL, response }
}
export const getConsulationDashboardDetailSuccess = (response) => {
    return { type: GET_CONSULATION_DASHBOARD_DETAIL_SUCCESS, response }
}
export const getConsulationDashboardDetailReset = () => {
    return { type: GET_CONSULATION_DASHBOARD_DETAIL_RESET }
}