export const GET_ARTICLE_DETAIL = "GET_ARTICLE_DETAIL"
export const GET_ARTICLE_DETAIL_FAIL = "GET_ARTICLE_DETAIL_FAIL"
export const GET_ARTICLE_DETAIL_SUCCESS = "GET_ARTICLE_DETAIL_SUCCESS"
export const GET_ARTICLE_DETAIL_RESET = "GET_ARTICLE_DETAIL_RESET"

export const getArticleDetail = (params) => {
    return { type: GET_ARTICLE_DETAIL, params }
}
export const getArticleDetailFail = (response) => {
    return { type: GET_ARTICLE_DETAIL_FAIL, response }
}
export const getArticleDetailSuccess = (response) => {
    return { type: GET_ARTICLE_DETAIL_SUCCESS, response }
}
export const getArticleDetailReset = () => {
    return { type: GET_ARTICLE_DETAIL_RESET }
}