export const SAVE_INSTITUTE = "SAVE_INSTITUTE"
export const SAVE_INSTITUTE_FAIL = "SAVE_INSTITUTE_FAIL"
export const SAVE_INSTITUTE_SUCCESS = "SAVE_INSTITUTE_SUCCESS"
export const SAVE_INSTITUTE_RESET = "SAVE_INSTITUTE_RESET"

export const saveInstitute = (params) => {
    return { type: SAVE_INSTITUTE, params }
}
export const saveInstituteFail = (response) => {
    return { type: SAVE_INSTITUTE_FAIL, response }
}
export const saveInstituteSuccess = (response) => {
    return { type: SAVE_INSTITUTE_SUCCESS, response }
}
export const saveInstituteReset = () => {
    return { type: SAVE_INSTITUTE_RESET }
}