export const GET_INSTITUTE_TEST_PRICING_DETAIL = "GET_INSTITUTE_TEST_PRICING_DETAIL"
export const GET_INSTITUTE_TEST_PRICING_DETAIL_FAIL = "GET_INSTITUTE_TEST_PRICING_DETAIL_FAIL"
export const GET_INSTITUTE_TEST_PRICING_DETAIL_SUCCESS = "GET_INSTITUTE_TEST_PRICING_DETAIL_SUCCESS"
export const GET_INSTITUTE_TEST_PRICING_DETAIL_RESET = "GET_INSTITUTE_TEST_PRICING_DETAIL_RESET"

export const getInstituteTestPricingDetail = (params) => {
    return { type: GET_INSTITUTE_TEST_PRICING_DETAIL, params }
}
export const getInstituteTestPricingDetailFail = (response) => {
    return { type: GET_INSTITUTE_TEST_PRICING_DETAIL_FAIL, response }
}
export const getInstituteTestPricingDetailSuccess = (response) => {
    return { type: GET_INSTITUTE_TEST_PRICING_DETAIL_SUCCESS, response }
}
export const getInstituteTestPricingDetailReset = () => {
    return { type: GET_INSTITUTE_TEST_PRICING_DETAIL_RESET }
}