import { call, put, takeLatest } from "redux-saga/effects"

/* OTHERS */
import { handleHttpErr } from "../utils"

// action
import {
    SAVE_TEST_MASTER,
    saveTestMasterFail,
    saveTestMasterSuccess
} from '../action/saveTestMasterAction'

// api 
import saveTestMasterApi from '../api/saveTestMasterApi'

export function* saveTestMasterSaga(action) {
    try {
        const response = yield call(() => saveTestMasterApi(action.params))
        if (response) {
            yield put(saveTestMasterSuccess(response, action))
        } else {
            yield put(saveTestMasterFail(response, action))
        }
    }
    catch (e) {
        handleHttpErr(e.response)
        yield put(saveTestMasterFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_TEST_MASTER, saveTestMasterSaga)
}