// action
import {
    GET_QUICK_SUPPORT_CATEGORY_LIST,
    GET_QUICK_SUPPORT_CATEGORY_LIST_FAIL,
    GET_QUICK_SUPPORT_CATEGORY_LIST_SUCCESS,
    GET_QUICK_SUPPORT_CATEGORY_LIST_RESET
} from '../action/getQuickSupportCategoryListAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    list: [],
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_QUICK_SUPPORT_CATEGORY_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_QUICK_SUPPORT_CATEGORY_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_QUICK_SUPPORT_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data.data.result,
            }
        case GET_QUICK_SUPPORT_CATEGORY_LIST_RESET:
            return initialState
        default:
            return state
    }
}