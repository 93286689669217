export const GET_ADMIN_TYPE_DETAIL = "GET_ADMIN_TYPE_DETAIL"
export const GET_ADMIN_TYPE_DETAIL_FAIL = "GET_ADMIN_TYPE_DETAIL_FAIL"
export const GET_ADMIN_TYPE_DETAIL_SUCCESS = "GET_ADMIN_TYPE_DETAIL_SUCCESS"
export const GET_ADMIN_TYPE_DETAIL_RESET = "GET_ADMIN_TYPE_DETAIL_RESET"

export const getAdminTypeDetail = (params) => {
    return { type: GET_ADMIN_TYPE_DETAIL, params }
}
export const getAdminTypeDetailFail = (response) => {
    return { type: GET_ADMIN_TYPE_DETAIL_FAIL, response }
}
export const getAdminTypeDetailSuccess = (response) => {
    return { type: GET_ADMIN_TYPE_DETAIL_SUCCESS, response }
}
export const getAdminTypeDetailReset = () => {
    return { type: GET_ADMIN_TYPE_DETAIL_RESET }
}