export const SAVE_COUPON = "SAVE_COUPON"
export const SAVE_COUPON_FAIL = "SAVE_COUPON_FAIL"
export const SAVE_COUPON_SUCCESS = "SAVE_COUPON_SUCCESS"
export const SAVE_COUPON_RESET = "SAVE_COUPON_RESET"

export const saveCoupon = (params) => {
    return { type: SAVE_COUPON, params }
}
export const saveCouponFail = (response) => {
    return { type: SAVE_COUPON_FAIL, response }
}
export const saveCouponSuccess = (response) => {
    return { type: SAVE_COUPON_SUCCESS, response }
}
export const saveCouponReset = () => {
    return { type: SAVE_COUPON_RESET }
}