const config = {
  api: {
    //  base_url: "http://localhost:8183/",
    // base_url: "http://3.7.97.20:8001/",
  //  base_url: "https://lilac-super-admin-api-stage.aiolos.solutions/",
  //  base_url: "https://lilac-super-admin-api-dev.aiolos.solutions/",
   base_url: "https://lilac-super-admin-api-stage.aiolos.solutions/",
  },
};
if (process.env.REACT_APP_ENV === "dev") {
  // config.api.base_url = "http://3.109.38.77:8185/";
  // config.api.base_url = "http://lilac-api.aiolos.solutions/";
  config.api.base_url = "https://lilac-super-admin-api-stage.aiolos.solutions/";
}
if (process.env.REACT_APP_ENV === "prod") {
  config.api.base_url = "http://lilac-super-admin-api.aiolos.solutions/";
}
export default config;
