export const VIEW_TEST_MASTER = "VIEW_TEST_MASTER"
export const VIEW_TEST_MASTER_FAIL = "VIEW_TEST_MASTER_FAIL"
export const VIEW_TEST_MASTER_SUCCESS = "VIEW_TEST_MASTER_SUCCESS"
export const VIEW_TEST_MASTER_RESET = "VIEW_TEST_MASTER_RESET"

export const viewTestMaster = (params) => {
    return { type: VIEW_TEST_MASTER, params }
}
export const viewTestMasterFail = (response) => {
    return { type: VIEW_TEST_MASTER_FAIL, response }
}
export const viewTestMasterSuccess = (response) => {
    return { type: VIEW_TEST_MASTER_SUCCESS, response }
}
export const viewTestMasterReset = () => {
    return { type: VIEW_TEST_MASTER_RESET }
}