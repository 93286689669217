// action
import {
    SYSTEM_USER_LIST,
    SYSTEM_USER_LIST_FAIL,
    SYSTEM_USER_LIST_SUCCESS,
    SYSTEM_USER_LIST_RESET
} from '../action/systemUserListAction'

// initialState
const initialState = {
    apiState: "",
    data: [],
    pagination: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SYSTEM_USER_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case SYSTEM_USER_LIST_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case SYSTEM_USER_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data.result,
                pagination: action.response.data.data.pagination,
            }
        case SYSTEM_USER_LIST_RESET:
            return initialState
        default:
            return state
    }
}