export const CHANGE_INSTITUTE_STATUS = "CHANGE_INSTITUTE_STATUS"
export const CHANGE_INSTITUTE_STATUS_FAIL = "CHANGE_INSTITUTE_STATUS_FAIL"
export const CHANGE_INSTITUTE_STATUS_SUCCESS = "CHANGE_INSTITUTE_STATUS_SUCCESS"
export const CHANGE_INSTITUTE_STATUS_RESET = "CHANGE_INSTITUTE_STATUS_RESET"

export const changeInstituteStatus = (params) => {
    return { type: CHANGE_INSTITUTE_STATUS, params }
}
export const changeInstituteStatusFail = (response) => {
    return { type: CHANGE_INSTITUTE_STATUS_FAIL, response }
}
export const changeInstituteStatusSuccess = (response) => {
    return { type: CHANGE_INSTITUTE_STATUS_SUCCESS, response }
}
export const changeInstituteStatusReset = () => {
    return { type: CHANGE_INSTITUTE_STATUS_RESET }
}