export const GET_DTRFS_LIST = "GET_DTRFS_LIST"
export const GET_DTRFS_LIST_FAIL = "GET_DTRFS_LIST_FAIL"
export const GET_DTRFS_LIST_SUCCESS = "GET_DTRFS_LIST_SUCCESS"
export const GET_DTRFS_LIST_RESET = "GET_DTRFS_LIST_RESET"

export const getDtrfsList = (params) => {
    return { type: GET_DTRFS_LIST, params }
}
export const getDtrfsListFail = (response) => {
    return { type: GET_DTRFS_LIST_FAIL, response }
}
export const getDtrfsListSuccess = (response) => {
    return { type: GET_DTRFS_LIST_SUCCESS, response }
}
export const getDtrfsListReset = () => {
    return { type: GET_DTRFS_LIST_RESET }
}