export const GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE = "GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE"
export const GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_FAIL = "GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_FAIL"
export const GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_SUCCESS = "GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_SUCCESS"
export const GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_RESET = "GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_RESET"

export const getInstituteTestPricingMasterInstitute = (params) => {
    return { type: GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE, params }
}
export const getInstituteTestPricingMasterInstituteFail = (response) => {
    return { type: GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_FAIL, response }
}
export const getInstituteTestPricingMasterInstituteSuccess = (response) => {
    return { type: GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_SUCCESS, response }
}
export const getInstituteTestPricingMasterInstituteReset = () => {
    return { type: GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE_RESET }
}