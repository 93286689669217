export const SAVE_USER_RIGHTS = "SAVE_USER_RIGHTS"
export const SAVE_USER_RIGHTS_FAIL = "SAVE_USER_RIGHTS_FAIL"
export const SAVE_USER_RIGHTS_SUCCESS = "SAVE_USER_RIGHTS_SUCCESS"
export const SAVE_USER_RIGHTS_RESET = "SAVE_USER_RIGHTS_RESET"

export const saveUserRights = (params) => {
    return { type: SAVE_USER_RIGHTS, params }
}
export const saveUserRightsFail = (response) => {
    return { type: SAVE_USER_RIGHTS_FAIL, response }
}
export const saveUserRightsSuccess = (response) => {
    return { type: SAVE_USER_RIGHTS_SUCCESS, response }
}
export const saveUserRightsReset = () => {
    return { type: SAVE_USER_RIGHTS_RESET }
}