// action
import {
    GET_PINCODE_LIST,
    GET_PINCODE_LIST_FAIL,
    GET_PINCODE_LIST_SUCCESS,
    GET_PINCODE_LIST_RESET
} from '../action/getPincodeListAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    list: [],
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_PINCODE_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_PINCODE_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_PINCODE_LIST_SUCCESS:
            console.log(action.response.data.data.result)
            return {
                ...state,
                apiState: "success",
                list: action.response.data.data.result,
            }
        case GET_PINCODE_LIST_RESET:
            return initialState
        default:
            return state
    }
}