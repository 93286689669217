export const SAVE_CONTAINER = "SAVE_CONTAINER"
export const SAVE_CONTAINER_FAIL = "SAVE_CONTAINER_FAIL"
export const SAVE_CONTAINER_SUCCESS = "SAVE_CONTAINER_SUCCESS"
export const SAVE_CONTAINER_RESET = "SAVE_CONTAINER_RESET"

export const saveContainer = (params) => {
    return { type: SAVE_CONTAINER, params }
}
export const saveContainerFail = (response) => {
    return { type: SAVE_CONTAINER_FAIL, response }
}
export const saveContainerSuccess = (response) => {
    return { type: SAVE_CONTAINER_SUCCESS, response }
}
export const saveContainerReset = () => {
    return { type: SAVE_CONTAINER_RESET }
}