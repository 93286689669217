export const GET_ROLE_MODULE_MAPPING_DETAIL = "GET_ROLE_MODULE_MAPPING_DETAIL"
export const GET_ROLE_MODULE_MAPPING_DETAIL_FAIL = "GET_ROLE_MODULE_MAPPING_DETAIL_FAIL"
export const GET_ROLE_MODULE_MAPPING_DETAIL_SUCCESS = "GET_ROLE_MODULE_MAPPING_DETAIL_SUCCESS"
export const GET_ROLE_MODULE_MAPPING_DETAIL_RESET = "GET_ROLE_MODULE_MAPPING_DETAIL_RESET"

export const getRoleModuleMappingDetail = (params) => {
    return { type: GET_ROLE_MODULE_MAPPING_DETAIL, params }
}
export const getRoleModuleMappingDetailFail = (response) => {
    return { type: GET_ROLE_MODULE_MAPPING_DETAIL_FAIL, response }
}
export const getRoleModuleMappingDetailSuccess = (response) => {
    return { type: GET_ROLE_MODULE_MAPPING_DETAIL_SUCCESS, response }
}
export const getRoleModuleMappingDetailReset = () => {
    return { type: GET_ROLE_MODULE_MAPPING_DETAIL_RESET }
}