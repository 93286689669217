export const DELETE_PREGNANCY_HIGHLIGHTS = "DELETE_PREGNANCY_HIGHLIGHTS"
export const DELETE_PREGNANCY_HIGHLIGHTS_FAIL = "DELETE_PREGNANCY_HIGHLIGHTS_FAIL"
export const DELETE_PREGNANCY_HIGHLIGHTS_SUCCESS = "DELETE_PREGNANCY_HIGHLIGHTS_SUCCESS"
export const DELETE_PREGNANCY_HIGHLIGHTS_RESET = "DELETE_PREGNANCY_HIGHLIGHTS_RESET"

export const deletePregnancyHighlights = (params) => {
    return { type: DELETE_PREGNANCY_HIGHLIGHTS, params }
}
export const deletePregnancyHighlightsFail = (response) => {
    return { type: DELETE_PREGNANCY_HIGHLIGHTS_FAIL, response }
}
export const deletePregnancyHighlightsSuccess = (response) => {
    return { type: DELETE_PREGNANCY_HIGHLIGHTS_SUCCESS, response }
}
export const deletePregnancyHighlightsReset = () => {
    return { type: DELETE_PREGNANCY_HIGHLIGHTS_RESET }
}