export const GET_QUICK_SUPPORT_CATEGORY_DETAIL = "GET_QUICK_SUPPORT_CATEGORY_DETAIL"
export const GET_QUICK_SUPPORT_CATEGORY_DETAIL_FAIL = "GET_QUICK_SUPPORT_CATEGORY_DETAIL_FAIL"
export const GET_QUICK_SUPPORT_CATEGORY_DETAIL_SUCCESS = "GET_QUICK_SUPPORT_CATEGORY_DETAIL_SUCCESS"
export const GET_QUICK_SUPPORT_CATEGORY_DETAIL_RESET = "GET_QUICK_SUPPORT_CATEGORY_DETAIL_RESET"

export const getQuickSupportCategoryDetail = (params) => {
    return { type: GET_QUICK_SUPPORT_CATEGORY_DETAIL, params }
}
export const getQuickSupportCategoryDetailFail = (response) => {
    return { type: GET_QUICK_SUPPORT_CATEGORY_DETAIL_FAIL, response }
}
export const getQuickSupportCategoryDetailSuccess = (response) => {
    return { type: GET_QUICK_SUPPORT_CATEGORY_DETAIL_SUCCESS, response }
}
export const getQuickSupportCategoryDetailReset = () => {
    return { type: GET_QUICK_SUPPORT_CATEGORY_DETAIL_RESET }
}