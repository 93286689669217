export const GET_PREGNANCY_HIGHLIGHTS_DETAIL = "GET_PREGNANCY_HIGHLIGHTS_DETAIL"
export const GET_PREGNANCY_HIGHLIGHTS_DETAIL_FAIL = "GET_PREGNANCY_HIGHLIGHTS_DETAIL_FAIL"
export const GET_PREGNANCY_HIGHLIGHTS_DETAIL_SUCCESS = "GET_PREGNANCY_HIGHLIGHTS_DETAIL_SUCCESS"
export const GET_PREGNANCY_HIGHLIGHTS_DETAIL_RESET = "GET_PREGNANCY_HIGHLIGHTS_DETAIL_RESET"

export const getPregnancyHighlightsDetail = (params) => {
    return { type: GET_PREGNANCY_HIGHLIGHTS_DETAIL, params }
}
export const getPregnancyHighlightsDetailFail = (response) => {
    return { type: GET_PREGNANCY_HIGHLIGHTS_DETAIL_FAIL, response }
}
export const getPregnancyHighlightsDetailSuccess = (response) => {
    return { type: GET_PREGNANCY_HIGHLIGHTS_DETAIL_SUCCESS, response }
}
export const getPregnancyHighlightsDetailReset = () => {
    return { type: GET_PREGNANCY_HIGHLIGHTS_DETAIL_RESET }
}