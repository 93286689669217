import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_INSTITUTE_DETAIL,
    getInstituteDetailFail,
    getInstituteDetailSuccess
} from '../action/getInstituteDetailAction'

// api 
import getInstituteDetailApi from '../api/getInstituteDetailApi'

export function* getInstituteDetailSaga(action) {
    try {
        const response = yield call(() => getInstituteDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getInstituteDetailSuccess(response, action))
        } else {
            yield put(getInstituteDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getInstituteDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_INSTITUTE_DETAIL, getInstituteDetailSaga)
}