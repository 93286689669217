export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST"
export const GET_EMPLOYEE_LIST_FAIL = "GET_EMPLOYEE_LIST_FAIL"
export const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS"
export const GET_EMPLOYEE_LIST_RESET = "GET_EMPLOYEE_LIST_RESET"

export const getEmployeeList = (params) => {
    return { type: GET_EMPLOYEE_LIST, params }
}
export const getEmployeeListFail = (response) => {
    return { type: GET_EMPLOYEE_LIST_FAIL, response }
}
export const getEmployeeListSuccess = (response) => {
    return { type: GET_EMPLOYEE_LIST_SUCCESS, response }
}
export const getEmployeeListReset = () => {
    return { type: GET_EMPLOYEE_LIST_RESET }
}