// action
import {
    LOGOUT,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS,
} from '../action/logoutAction'

// initialState
const initialState = {
    apiState: "",
    data: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                apiState: "loading"
            }
        case LOGOUT_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                apiState: "success"
            }
        default:
            return state
    }
}