export const DOCTOR_SIGNUP_REQUEST_LIST = "DOCTOR_SIGNUP_REQUEST_LIST"
export const DOCTOR_SIGNUP_REQUEST_LIST_FAIL = "DOCTOR_SIGNUP_REQUEST_LIST_FAIL"
export const DOCTOR_SIGNUP_REQUEST_LIST_SUCCESS = "DOCTOR_SIGNUP_REQUEST_LIST_SUCCESS"
export const DOCTOR_SIGNUP_REQUEST_LIST_RESET = "DOCTOR_SIGNUP_REQUEST_LIST_RESET"

export const doctorSignupRequestList = (params) => {
    return { type: DOCTOR_SIGNUP_REQUEST_LIST, params }
}
export const doctorSignupRequestListFail = (response) => {
    return { type: DOCTOR_SIGNUP_REQUEST_LIST_FAIL, response }
}
export const doctorSignupRequestListSuccess = (response) => {
    return { type: DOCTOR_SIGNUP_REQUEST_LIST_SUCCESS, response }
}
export const doctorSignupRequestListReset = () => {
    return { type: DOCTOR_SIGNUP_REQUEST_LIST_RESET }
}