export const GET_CONTAINER_LIST = "GET_CONTAINER_LIST"
export const GET_CONTAINER_LIST_FAIL = "GET_CONTAINER_LIST_FAIL"
export const GET_CONTAINER_LIST_SUCCESS = "GET_CONTAINER_LIST_SUCCESS"
export const GET_CONTAINER_LIST_RESET = "GET_CONTAINER_LIST_RESET"

export const getContainerList = (params) => {
    return { type: GET_CONTAINER_LIST, params }
}
export const getContainerListFail = (response) => {
    return { type: GET_CONTAINER_LIST_FAIL, response }
}
export const getContainerListSuccess = (response) => {
    return { type: GET_CONTAINER_LIST_SUCCESS, response }
}
export const getContainerListReset = () => {
    return { type: GET_CONTAINER_LIST_RESET }
}