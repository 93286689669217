export const GET_MODULE_LIST = "GET_MODULE_LIST"
export const GET_MODULE_LIST_FAIL = "GET_MODULE_LIST_FAIL"
export const GET_MODULE_LIST_SUCCESS = "GET_MODULE_LIST_SUCCESS"
export const GET_MODULE_LIST_RESET = "GET_MODULE_LIST_RESET"

export const getModuleList = (params) => {
    return { type: GET_MODULE_LIST, params }
}
export const getModuleListFail = (response) => {
    return { type: GET_MODULE_LIST_FAIL, response }
}
export const getModuleListSuccess = (response) => {
    return { type: GET_MODULE_LIST_SUCCESS, response }
}
export const getModuleListReset = () => {
    return { type: GET_MODULE_LIST_RESET }
}