export const DELETE_ADMIN_TYPE = "DELETE_ADMIN_TYPE"
export const DELETE_ADMIN_TYPE_FAIL = "DELETE_ADMIN_TYPE_FAIL"
export const DELETE_ADMIN_TYPE_SUCCESS = "DELETE_ADMIN_TYPE_SUCCESS"
export const DELETE_ADMIN_TYPE_RESET = "DELETE_ADMIN_TYPE_RESET"

export const deleteAdminType = (params) => {
    return { type: DELETE_ADMIN_TYPE, params }
}
export const deleteAdminTypeFail = (response) => {
    return { type: DELETE_ADMIN_TYPE_FAIL, response }
}
export const deleteAdminTypeSuccess = (response) => {
    return { type: DELETE_ADMIN_TYPE_SUCCESS, response }
}
export const deleteAdminTypeReset = () => {
    return { type: DELETE_ADMIN_TYPE_RESET }
}