import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_DOCTOR,
    saveDoctorFail,
    saveDoctorSuccess
} from '../action/saveDoctorAction'

// api 
import saveDoctorApi from '../api/saveDoctorApi'

export function* saveDoctorSaga(action) {
    try {
        const response = yield call(() => saveDoctorApi(action.params))
        if (response.data.status === 1) {
            yield put(saveDoctorSuccess(response, action))
        } else {
            yield put(saveDoctorFail(response, action))
        }
    }
    catch (e) {
        yield put(saveDoctorFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_DOCTOR, saveDoctorSaga)
}