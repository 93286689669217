export const GET_PINCODE_DETAIL = "GET_PINCODE_DETAIL"
export const GET_PINCODE_DETAIL_FAIL = "GET_PINCODE_DETAIL_FAIL"
export const GET_PINCODE_DETAIL_SUCCESS = "GET_PINCODE_DETAIL_SUCCESS"
export const GET_PINCODE_DETAIL_RESET = "GET_PINCODE_DETAIL_RESET"

export const getPincodeDetail = (params) => {
    return { type: GET_PINCODE_DETAIL, params }
}
export const getPincodeDetailFail = (response) => {
    return { type: GET_PINCODE_DETAIL_FAIL, response }
}
export const getPincodeDetailSuccess = (response) => {
    return { type: GET_PINCODE_DETAIL_SUCCESS, response }
}
export const getPincodeDetailReset = () => {
    return { type: GET_PINCODE_DETAIL_RESET }
}