export const GET_SUB_GROUP_LIST = "GET_SUB_GROUP_LIST"
export const GET_SUB_GROUP_LIST_FAIL = "GET_SUB_GROUP_LIST_FAIL"
export const GET_SUB_GROUP_LIST_SUCCESS = "GET_SUB_GROUP_LIST_SUCCESS"
export const GET_SUB_GROUP_LIST_RESET = "GET_SUB_GROUP_LIST_RESET"

export const getSubGroupList = (params) => {
    return { type: GET_SUB_GROUP_LIST, params }
}
export const getSubGroupListFail = (response) => {
    return { type: GET_SUB_GROUP_LIST_FAIL, response }
}
export const getSubGroupListSuccess = (response) => {
    return { type: GET_SUB_GROUP_LIST_SUCCESS, response }
}
export const getSubGroupListReset = () => {
    return { type: GET_SUB_GROUP_LIST_RESET }
}