export const SAVE_PREGNANCY_HIGHLIGHTS = "SAVE_PREGNANCY_HIGHLIGHTS"
export const SAVE_PREGNANCY_HIGHLIGHTS_FAIL = "SAVE_PREGNANCY_HIGHLIGHTS_FAIL"
export const SAVE_PREGNANCY_HIGHLIGHTS_SUCCESS = "SAVE_PREGNANCY_HIGHLIGHTS_SUCCESS"
export const SAVE_PREGNANCY_HIGHLIGHTS_RESET = "SAVE_PREGNANCY_HIGHLIGHTS_RESET"

export const savePregnancyHighlights = (params) => {
    return { type: SAVE_PREGNANCY_HIGHLIGHTS, params }
}
export const savePregnancyHighlightsFail = (response) => {
    return { type: SAVE_PREGNANCY_HIGHLIGHTS_FAIL, response }
}
export const savePregnancyHighlightsSuccess = (response) => {
    return { type: SAVE_PREGNANCY_HIGHLIGHTS_SUCCESS, response }
}
export const savePregnancyHighlightsReset = () => {
    return { type: SAVE_PREGNANCY_HIGHLIGHTS_RESET }
}