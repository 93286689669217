import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_SAMPLE,
    saveSampleFail,
    saveSampleSuccess
} from '../action/saveSampleAction'

// api 
import saveSampleApi from '../api/saveSampleApi'

export function* saveSampleSaga(action) {
    try {
        const response = yield call(() => saveSampleApi(action.params))
        if (response) {
            yield put(saveSampleSuccess(response, action))
        }
        else {
            yield put(saveSampleFail(response, action))
        }
    }
    catch (e) {
        yield put(saveSampleFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_SAMPLE, saveSampleSaga)
}