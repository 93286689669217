export const SYSTEM_USER_DETAIL = "SYSTEM_USER_DETAIL"
export const SYSTEM_USER_DETAIL_FAIL = "SYSTEM_USER_DETAIL_FAIL"
export const SYSTEM_USER_DETAIL_SUCCESS = "SYSTEM_USER_DETAIL_SUCCESS"
export const SYSTEM_USER_DETAIL_RESET = "SYSTEM_USER_DETAIL_RESET"

export const systemUserDetail = (params) => {
    return { type: SYSTEM_USER_DETAIL, params }
}
export const systemUserDetailFail = (response) => {
    return { type: SYSTEM_USER_DETAIL_FAIL, response }
}
export const systemUserDetailSuccess = (response) => {
    return { type: SYSTEM_USER_DETAIL_SUCCESS, response }
}
export const systemUserDetailReset = () => {
    return { type: SYSTEM_USER_DETAIL_RESET }
}