export const SAVE_INSTITUTE_TEST_PRICING_MASTER = "SAVE_INSTITUTE_TEST_PRICING_MASTER"
export const SAVE_INSTITUTE_TEST_PRICING_MASTER_FAIL = "SAVE_INSTITUTE_TEST_PRICING_MASTER_FAIL"
export const SAVE_INSTITUTE_TEST_PRICING_MASTER_SUCCESS = "SAVE_INSTITUTE_TEST_PRICING_MASTER_SUCCESS"
export const SAVE_INSTITUTE_TEST_PRICING_MASTER_RESET = "SAVE_INSTITUTE_TEST_PRICING_MASTER_RESET"

export const saveInstituteTestPricingMaster = (params) => {
    return { type: SAVE_INSTITUTE_TEST_PRICING_MASTER, params }
}
export const saveInstituteTestPricingMasterFail = (response) => {
    return { type: SAVE_INSTITUTE_TEST_PRICING_MASTER_FAIL, response }
}
export const saveInstituteTestPricingMasterSuccess = (response) => {
    return { type: SAVE_INSTITUTE_TEST_PRICING_MASTER_SUCCESS, response }
}
export const saveInstituteTestPricingMasterReset = () => {
    return { type: SAVE_INSTITUTE_TEST_PRICING_MASTER_RESET }
}