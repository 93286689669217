export const GET_INSTITUTE_TEST_PRICING_MASTER = "GET_INSTITUTE_TEST_PRICING_MASTER"
export const GET_INSTITUTE_TEST_PRICING_MASTER_FAIL = "GET_INSTITUTE_TEST_PRICING_MASTER_FAIL"
export const GET_INSTITUTE_TEST_PRICING_MASTER_SUCCESS = "GET_INSTITUTE_TEST_PRICING_MASTER_SUCCESS"
export const GET_INSTITUTE_TEST_PRICING_MASTER_RESET = "GET_INSTITUTE_TEST_PRICING_MASTER_RESET"

export const getInstituteTestPricingMaster = (params) => {
    return { type: GET_INSTITUTE_TEST_PRICING_MASTER, params }
}
export const getInstituteTestPricingMasterFail = (response) => {
    return { type: GET_INSTITUTE_TEST_PRICING_MASTER_FAIL, response }
}
export const getInstituteTestPricingMasterSuccess = (response) => {
    return { type: GET_INSTITUTE_TEST_PRICING_MASTER_SUCCESS, response }
}
export const getInstituteTestPricingMasterReset = () => {
    return { type: GET_INSTITUTE_TEST_PRICING_MASTER_RESET }
}