export const GET_CONSULATION_DASHBOARD_LIST = "GET_CONSULATION_DASHBOARD_LIST"
export const GET_CONSULATION_DASHBOARD_LIST_FAIL = "GET_CONSULATION_DASHBOARD_LIST_FAIL"
export const GET_CONSULATION_DASHBOARD_LIST_SUCCESS = "GET_CONSULATION_DASHBOARD_LIST_SUCCESS"
export const GET_CONSULATION_DASHBOARD_LIST_RESET = "GET_CONSULATION_DASHBOARD_LIST_RESET"

export const getConsulationDashboardList = (params) => {
    return { type: GET_CONSULATION_DASHBOARD_LIST, params }
}
export const getConsulationDashboardListFail = (response) => {
    return { type: GET_CONSULATION_DASHBOARD_LIST_FAIL, response }
}
export const getConsulationDashboardListSuccess = (response) => {
    return { type: GET_CONSULATION_DASHBOARD_LIST_SUCCESS, response }
}
export const getConsulationDashboardListReset = () => {
    return { type: GET_CONSULATION_DASHBOARD_LIST_RESET }
}