export const DELETE_ARTICLE = "DELETE_ARTICLE"
export const DELETE_ARTICLE_FAIL = "DELETE_ARTICLE_FAIL"
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS"
export const DELETE_ARTICLE_RESET = "DELETE_ARTICLE_RESET"

export const deleteArticle = (params) => {
    return { type: DELETE_ARTICLE, params }
}
export const deleteArticleFail = (response) => {
    return { type: DELETE_ARTICLE_FAIL, response }
}
export const deleteArticleSuccess = (response) => {
    return { type: DELETE_ARTICLE_SUCCESS, response }
}
export const deleteArticleReset = () => {
    return { type: DELETE_ARTICLE_RESET }
}