export const SAVE_SAMPLE_CONTAINER_TEST_MAP = "SAVE_SAMPLE_CONTAINER_TEST_MAP"
export const SAVE_SAMPLE_CONTAINER_TEST_MAP_FAIL = "SAVE_SAMPLE_CONTAINER_TEST_MAP_FAIL"
export const SAVE_SAMPLE_CONTAINER_TEST_MAP_SUCCESS = "SAVE_SAMPLE_CONTAINER_TEST_MAP_SUCCESS"
export const SAVE_SAMPLE_CONTAINER_TEST_MAP_RESET = "SAVE_SAMPLE_CONTAINER_TEST_MAP_RESET"

export const saveSampleContainerTestMap = (params) => {
    return { type: SAVE_SAMPLE_CONTAINER_TEST_MAP, params }
}
export const saveSampleContainerTestMapFail = (response) => {
    return { type: SAVE_SAMPLE_CONTAINER_TEST_MAP_FAIL, response }
}
export const saveSampleContainerTestMapSuccess = (response) => {
    return { type: SAVE_SAMPLE_CONTAINER_TEST_MAP_SUCCESS, response }
}
export const saveSampleContainerTestMapReset = () => {
    return { type: SAVE_SAMPLE_CONTAINER_TEST_MAP_RESET }
}