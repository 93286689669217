import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Tag, Menu, Modal, Row, Col,Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { DownloadOutlined } from "@ant-design/icons"

import moment from 'moment'


/* CUSTOM COMPONENT */
import Header from "../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, ViewBox, ViewLabel, ViewValue } from "../../component/Xcomponent"


/* ACTION */
import { getPaymentHistory, getPaymentHistoryReset } from '../../action/getPaymentHistoryAction';
import { getPaymentHistoryList } from "../../action/getPaymentHistoryListAction";

var Excel = require("exceljs");
var wb = new Excel.Workbook();

const PaymentHistory = props => {
    // VARIABLES
    const { getPaymentHistory, getPaymentHistoryReset, getPaymentHistoryState,getPaymentHistoryList, getPaymentHistoryListState,  } = props
    const [pagination, setPagination] = useState({
        page_no: 1,
        records_per_page: 10
    })
    const [modelDetails, setModelDetails] = useState({})
    const columns = [
        {
            // title: 'Razorpay orderID',
            // new code
            title:"Order ID",
            dataIndex: 'rzp_order_id',
            key: 'rzp_order_id',
            render: row => <a onClick={_ => {
                setIsModalVisible(true)
                setModelDetails({
                    razorpay_order_id: row.cashfree_cf_order_id,
                    patient_name: row.patient_id?.name.firstName + " " + row.patient_id?.name.lastName,
                    email: row.patient_id?.email ?? "",
                    phone: row.patient_id?.phone ?? "",
                    doctor_name: row.doctor_id ? `${row.doctor_id.fname} ${row.doctor_id.lname}` : '',
                    status: row.status?row.status:"",
                    booking_date: row.to_date ? row.to_date :"",
                    time_slot_start: row.time_slot_start,
                    time_slot_end: row.time_slot_end?row.time_slot_end:"",
                    booking_type: row.doctor_id?.type ?? "",
                    amount: row.amount?row.amount:'' ,
                })
            }} >{row.cashfree_cf_order_id}</a>
        },
        {
            title: 'Patient',
            dataIndex: 'patient_name',
            key: 'patient_name',
        },
        
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        
        {
            title: 'Mobile',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Doctor',
            dataIndex: 'doctor_name',
            key: 'doctor_name',
        },
        {
            title: 'Coupon',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            // title: 'Booking Date',
            title: 'Booked On',
            dataIndex: 'booking_date',
            key: 'booking_date',
        },
        {
            title:"Schedule For",
            dataIndex:"scheduled_for",
            key:"scheduled_for"
        },
        
        {
            title: 'Consultation Type',
            dataIndex: 'booking_type',
            key: 'booking_type',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right'
        },
        {
            title: 'Payment Status',
            dataIndex: 'status',
            key: 'status',
            render: status => {
                if (status === 'Cancelled') return <Tag color="magenta">CANCELLED</Tag>
                if (status === 'Success') return <Tag color="green">SUCCESS</Tag>
                if (status === 'Initiated') return <Tag color="orange">INITIATED</Tag>
            }
        },
    ]
    const [tableData, setTableData] = useState([])
    const [activeTab, setActiveTab] = useState('Appointments')
    const [redirect, setRedirect] = useState([false, ''])
    const [isModalVisible, setIsModalVisible] = useState(false);

    // CALLBACKS

    useEffect(()=>{

        if(getPaymentHistoryListState.apiState=="success")
        {
            let appointment_list = getPaymentHistoryListState.list;
            console.log("appointment_list",appointment_list);
            const workbook = wb.addWorksheet("Employer_Master");
            const headers = [
                // 'Payment ID',
                'Patient Name',
                'Patient Mobile',
                'Patient Email',
                'Booking Gender',
                // 'Consultation Type',
                'First / follow-up',
                'Consultation status',
                'Payment Status',
                'Doctor Type',
                'Referred By',
                'Consultant',
                //patient location,
                "Consultation Price",
                "Discount",
                "Final Price",
                "Coupon code",
                //"Payment Mode",
                //"Existing Customer"
                //"Join Link"     
                'Booking Date',
                'Schedule Date',
                // 'Amount', 
            ];

            workbook.getRow(1).values = headers;

            appointment_list.forEach((element,index) => {
                const rowIndex = index + 2;
                let patient_fname = element.patient_id?.name?.firstName ?? "";
                let patient_lname = element.patient_id?.name?.lastName ?? "";

                let doctor_fname = element.doctor_id?.fname ?? "";
                let doctor_lname = element.doctor_id?.lname ?? "";
                workbook.getRow(rowIndex).values = [
                    // element.razorpay_order_id,
                    patient_fname+" "+patient_lname,
                    element.patient_id?.phone ?? "", 
                    element.patient_id?.email ?? "",
                    element.gender,
                    //consultation type
                    element.is_this_your_first_consultation,
                    element.patient_joined && element.doctor_joined ? "closed" : element.status,
                    element.computed_payment_status,
                    element.doctor_id?.type ?? "",
                    element.referred_by,
                    doctor_fname+" "+doctor_lname,
                    //patient location,
                    // consultation price,
                    element.amount,
                    element.discount,
                    element.amount-element.discount,
                    element.coupon_code,
                    //"Payment Mode",
                    //"Existing Customer",
                    //"Join Link"      
                    element.createdAt ? moment( element.createdAt ).format('DD MMM YYYY') :"",
                    element.to_date ? moment( element.to_date ).format('DD MMM YYYY') :"",     
                ];        
            });
            wb.xlsx.writeBuffer().then((buffer) => {
                const fileData = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileUrl = URL.createObjectURL(fileData);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Appointment List.xlsx';
                link.click();
            });
        }
        else if(getPaymentHistoryListState=="error")
        {
            console.log("No Appointments");
        }
    },[getPaymentHistoryListState]);

    useEffect(() => {
        getPaymentHistory({
            page_no: pagination.page_no,
            records_per_page: pagination.records_per_page
        })
    }, [pagination])

    useEffect(() => {
        if (getPaymentHistoryState.apiState === 'success') {
            let tableRows = []
            getPaymentHistoryState.list.map(row => {
                console.log('row.cashfree_cf_order_id',row.cashfree_cf_order_id)
                let patient_fname = row.patient_id?.name?.firstName ?? "";
                let patient_lname = row.patient_id?.name?.lastName ?? "";

                tableRows.push({
                    key: row.id,
                    rzp_order_id: row,
                    patient_name: patient_fname + " " + patient_lname,
                    email:row.patient_id?.email?? "",
                    phone:row.patient_id?.phone?? "",
                    doctor_name: row.doctor_id ? `${row.doctor_id.fname} ${row.doctor_id.lname}` : '',
                    coupon_code:row.coupon_code,
                    amount: `₹${row.amount}`,
                    status: row.computed_payment_status,
                    booking_date: row.createdAt ? moment( row.createdAt ).format('DD MMM YYYY') :"",
                    scheduled_for: row.to_date ? moment( row.to_date ).format('DD MMM YYYY') :"",
                    booking_type: row.doctor_id?.type ?? "",
                })
            })
            setTableData(tableRows)
        }
    }, [getPaymentHistoryState])




    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                title="Payment History for Appointments"
                rightChildren={
                    <>
                        <Button type="primary"  onClick={()=>getPaymentHistoryList()} ><DownloadOutlined />Download Appointments</Button>
                    </>
                }
            />
            <PageContainer>
                <Menu mode="horizontal" selectedKeys={activeTab} onClick={(info) => {
                    setActiveTab(info.key)
                    if (info.key === 'Appointments') setRedirect(['true', '/payment-history'])
                    if (info.key === 'Coupons') setRedirect(['true', '/payment-history-coupon'])
                }}>
                    <Menu.Item key="Appointments" >
                        Appointments
                    </Menu.Item>
                    <Menu.Item key="Coupons" >
                        Coupons
                    </Menu.Item>
                </Menu>
                
                <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                
                />
                <Xpagination
                    pageSizeOptions={['25', '50', '100', '500']}
                    showSizeChanger
                    total={getPaymentHistoryState.total_records}
                    onChange={(page, pageSize) => setPagination({ page_no: page, records_per_page: pageSize })}
                    current={pagination.page_no}
                    defaultPageSize={10}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
                <Modal title="Payment Details" visible={isModalVisible}
                    footer={null}
                    onCancel={_ => setIsModalVisible(false)}
                >
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                {/* <ViewLabel>Razorpay Order ID</ViewLabel> */}
                                {/* new code */}
                                <ViewLabel>Order ID</ViewLabel>
                                <ViewValue>{modelDetails.razorpay_order_id}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Patient Name</ViewLabel>
                                <ViewValue>{modelDetails.patient_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Patient Email</ViewLabel>
                                <ViewValue>{modelDetails.email}</ViewValue>
                            </ViewBox>
                        </Col>
                        
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Patient Mobile</ViewLabel>
                                <ViewValue>{modelDetails.phone}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Doctor Name</ViewLabel>
                                <ViewValue>{modelDetails.doctor_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Status</ViewLabel>
                                <ViewValue style={{ textTransform: 'capitalize' }} >{modelDetails.status}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Booking On</ViewLabel>
                                <ViewValue>{moment(modelDetails.booking_date).format('DD MMM YYYY')}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Time Slot</ViewLabel>
                                <ViewValue>{modelDetails.time_slot_start} - {modelDetails.time_slot_end}</ViewValue>
                            </ViewBox>
                        </Col>
                        
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Consultation Type</ViewLabel>
                                <ViewValue>{modelDetails.booking_type}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Amount</ViewLabel>
                                <ViewValue>{`₹${modelDetails.amount}`}</ViewValue>
                            </ViewBox>
                        </Col>
                    </Row>
                </Modal>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getPaymentHistoryState: state.getPaymentHistory,
    getPaymentHistoryListState: state.getPaymentHistoryList,
});
const mapDispatchToProps = (dispatch) => ({
    getPaymentHistory: (params) => dispatch(getPaymentHistory(params)),
    getPaymentHistoryReset: () => dispatch(getPaymentHistoryReset()),
    getPaymentHistoryList: (params) => dispatch(getPaymentHistoryList(params))

});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
