export const SAVE_MODULE = "SAVE_MODULE"
export const SAVE_MODULE_FAIL = "SAVE_MODULE_FAIL"
export const SAVE_MODULE_SUCCESS = "SAVE_MODULE_SUCCESS"
export const SAVE_MODULE_RESET = "SAVE_MODULE_RESET"

export const saveModule = (params) => {
    return { type: SAVE_MODULE, params }
}
export const saveModuleFail = (response) => {
    return { type: SAVE_MODULE_FAIL, response }
}
export const saveModuleSuccess = (response) => {
    return { type: SAVE_MODULE_SUCCESS, response }
}
export const saveModuleReset = () => {
    return { type: SAVE_MODULE_RESET }
}