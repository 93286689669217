export const DELETE_TEST_MASTER = "DELETE_TEST_MASTER"
export const DELETE_TEST_MASTER_FAIL = "DELETE_TEST_MASTER_FAIL"
export const DELETE_TEST_MASTER_SUCCESS = "DELETE_TEST_MASTER_SUCCESS"
export const DELETE_TEST_MASTER_RESET = "DELETE_TEST_MASTER_RESET"

export const deleteTestMaster = (params) => {
    return { type: DELETE_TEST_MASTER, params }
}
export const deleteTestMasterFail = (response) => {
    return { type: DELETE_TEST_MASTER_FAIL, response }
}
export const deleteTestMasterSuccess = (response) => {
    return { type: DELETE_TEST_MASTER_SUCCESS, response }
}
export const deleteTestMasterReset = () => {
    return { type: DELETE_TEST_MASTER_RESET }
}
