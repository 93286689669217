export const GET_PATIENT_LIST = "GET_PATIENT_LIST"
export const GET_PATIENT_LIST_FAIL = "GET_PATIENT_LIST_FAIL"
export const GET_PATIENT_LIST_SUCCESS = "GET_PATIENT_LIST_SUCCESS"
export const GET_PATIENT_LIST_RESET = "GET_PATIENT_LIST_RESET"

export const getPatientList = (params) => {
    return { type: GET_PATIENT_LIST, params }
}
export const getPatientListFail = (response) => {
    return { type: GET_PATIENT_LIST_FAIL, response }
}
export const getPatientListSuccess = (response) => {
    return { type: GET_PATIENT_LIST_SUCCESS, response }
}
export const getPatientListReset = () => {
    return { type: GET_PATIENT_LIST_RESET }
}