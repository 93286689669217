import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_INSTITUTE,
    saveInstituteFail,
    saveInstituteSuccess
} from '../action/saveInstituteAction'

// api 
import saveInstituteApi from '../api/saveInstituteApi'

export function* saveInstituteSaga(action) {
    try {
        const response = yield call(() => saveInstituteApi(action.params))
        if (response.data.status === 1) {
            yield put(saveInstituteSuccess(response, action))
        } else {
            yield put(saveInstituteFail(response, action))
        }
    }
    catch (e) {
        yield put(saveInstituteFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_INSTITUTE, saveInstituteSaga)
}