export const SAVE_ROLE_MODULE_MAPPING = "SAVE_ROLE_MODULE_MAPPING"
export const SAVE_ROLE_MODULE_MAPPING_FAIL = "SAVE_ROLE_MODULE_MAPPING_FAIL"
export const SAVE_ROLE_MODULE_MAPPING_SUCCESS = "SAVE_ROLE_MODULE_MAPPING_SUCCESS"
export const SAVE_ROLE_MODULE_MAPPING_RESET = "SAVE_ROLE_MODULE_MAPPING_RESET"

export const saveRoleModuleMapping = (params) => {
    return { type: SAVE_ROLE_MODULE_MAPPING, params }
}
export const saveRoleModuleMappingFail = (response) => {
    return { type: SAVE_ROLE_MODULE_MAPPING_FAIL, response }
}
export const saveRoleModuleMappingSuccess = (response) => {
    return { type: SAVE_ROLE_MODULE_MAPPING_SUCCESS, response }
}
export const saveRoleModuleMappingReset = () => {
    return { type: SAVE_ROLE_MODULE_MAPPING_RESET }
}