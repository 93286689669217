export const DELETE_MODULE = "DELETE_MODULE"
export const DELETE_MODULE_FAIL = "DELETE_MODULE_FAIL"
export const DELETE_MODULE_SUCCESS = "DELETE_MODULE_SUCCESS"
export const DELETE_MODULE_RESET = "DELETE_MODULE_RESET"

export const deleteModule = (params) => {
    return { type: DELETE_MODULE, params }
}
export const deleteModuleFail = (response) => {
    return { type: DELETE_MODULE_FAIL, response }
}
export const deleteModuleSuccess = (response) => {
    return { type: DELETE_MODULE_SUCCESS, response }
}
export const deleteModuleReset = () => {
    return { type: DELETE_MODULE_RESET }
}