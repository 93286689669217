import { call, put, takeLatest, } from 'redux-saga/effects'

/* OTHERS */
import { handleHttpErr } from "../utils"

// action
import {
    GET_LIST_TEST_MASTER,
    getListTestMasterFail,
    getListTestMasterSuccess
} from '../action/getListTestMasterActions'

// api
import getListTestMasterApi from '../api/getListTestMasterApi'


export function* getListTestMasterSaga(action) {
    try {
        // const response = yield call(() => getListTestMasterApi(action.params))
        const response = yield call(getListTestMasterApi, action.params)
        if (response.status === 1) {
            yield put(getListTestMasterSuccess(response, action))
        }
        else {
            yield put(getListTestMasterFail(response, action))
        }
    }
    catch (e) {
        handleHttpErr(e.response)
        yield put(getListTestMasterFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GET_LIST_TEST_MASTER, getListTestMasterSaga)
}