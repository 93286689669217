export const GET_MODULE_DETAIL = "GET_MODULE_DETAIL"
export const GET_MODULE_DETAIL_FAIL = "GET_MODULE_DETAIL_FAIL"
export const GET_MODULE_DETAIL_SUCCESS = "GET_MODULE_DETAIL_SUCCESS"
export const GET_MODULE_DETAIL_RESET = "GET_MODULE_DETAIL_RESET"

export const getModuleDetail = (params) => {
    return { type: GET_MODULE_DETAIL, params }
}
export const getModuleDetailFail = (response) => {
    return { type: GET_MODULE_DETAIL_FAIL, response }
}
export const getModuleDetailSuccess = (response) => {
    return { type: GET_MODULE_DETAIL_SUCCESS, response }
}
export const getModuleDetailReset = () => {
    return { type: GET_MODULE_DETAIL_RESET }
}