export const SAVE_QUICK_SUPPORT_CATEGORY = "SAVE_QUICK_SUPPORT_CATEGORY"
export const SAVE_QUICK_SUPPORT_CATEGORY_FAIL = "SAVE_QUICK_SUPPORT_CATEGORY_FAIL"
export const SAVE_QUICK_SUPPORT_CATEGORY_SUCCESS = "SAVE_QUICK_SUPPORT_CATEGORY_SUCCESS"
export const SAVE_QUICK_SUPPORT_CATEGORY_RESET = "SAVE_QUICK_SUPPORT_CATEGORY_RESET"

export const saveQuickSupportCategory = (params) => {
    return { type: SAVE_QUICK_SUPPORT_CATEGORY, params }
}
export const saveQuickSupportCategoryFail = (response) => {
    return { type: SAVE_QUICK_SUPPORT_CATEGORY_FAIL, response }
}
export const saveQuickSupportCategorySuccess = (response) => {
    return { type: SAVE_QUICK_SUPPORT_CATEGORY_SUCCESS, response }
}
export const saveQuickSupportCategoryReset = () => {
    return { type: SAVE_QUICK_SUPPORT_CATEGORY_RESET }
}