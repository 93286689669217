export const SAVE_QUICK_SUPPORT_QUESTION_ANSWER = "SAVE_QUICK_SUPPORT_QUESTION_ANSWER"
export const SAVE_QUICK_SUPPORT_QUESTION_ANSWER_FAIL = "SAVE_QUICK_SUPPORT_QUESTION_ANSWER_FAIL"
export const SAVE_QUICK_SUPPORT_QUESTION_ANSWER_SUCCESS = "SAVE_QUICK_SUPPORT_QUESTION_ANSWER_SUCCESS"
export const SAVE_QUICK_SUPPORT_QUESTION_ANSWER_RESET = "SAVE_QUICK_SUPPORT_QUESTION_ANSWER_RESET"

export const saveQuickSupportQuestionAnswer = (params) => {
    return { type: SAVE_QUICK_SUPPORT_QUESTION_ANSWER, params }
}
export const saveQuickSupportQuestionAnswerFail = (response) => {
    return { type: SAVE_QUICK_SUPPORT_QUESTION_ANSWER_FAIL, response }
}
export const saveQuickSupportQuestionAnswerSuccess = (response) => {
    return { type: SAVE_QUICK_SUPPORT_QUESTION_ANSWER_SUCCESS, response }
}
export const saveQuickSupportQuestionAnswerReset = () => {
    return { type: SAVE_QUICK_SUPPORT_QUESTION_ANSWER_RESET }
}