export const SAVE_SAMPLE = "SAVE_SAMPLE"
export const SAVE_SAMPLE_FAIL = "SAVE_SAMPLE_FAIL"
export const SAVE_SAMPLE_SUCCESS = "SAVE_SAMPLE_SUCCESS"
export const SAVE_SAMPLE_RESET = "SAVE_SAMPLE_RESET"

export const saveSample = (params) => {
    return { type: SAVE_SAMPLE, params }
}
export const saveSampleFail = (response) => {
    return { type: SAVE_SAMPLE_FAIL, response }
}
export const saveSampleSuccess = (response) => {
    return { type: SAVE_SAMPLE_SUCCESS, response }
}
export const saveSampleReset = () => {
    return { type: SAVE_SAMPLE_RESET }
}