// action
import {
    SAVE_DOCTOR,
    SAVE_DOCTOR_FAIL,
    SAVE_DOCTOR_SUCCESS,
    SAVE_DOCTOR_RESET
} from '../action/saveDoctorAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_DOCTOR:
            return {
                ...state,
                apiState: "loading"
            }
        case SAVE_DOCTOR_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case SAVE_DOCTOR_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
            }
        case SAVE_DOCTOR_RESET:
            return initialState
        default:
            return state
    }
}