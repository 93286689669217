export const SYSTEM_USER_LIST = "SYSTEM_USER_LIST"
export const SYSTEM_USER_LIST_FAIL = "SYSTEM_USER_LIST_FAIL"
export const SYSTEM_USER_LIST_SUCCESS = "SYSTEM_USER_LIST_SUCCESS"
export const SYSTEM_USER_LIST_RESET = "SYSTEM_USER_LIST_RESET"

export const systemUserList = (params) => {
    return { type: SYSTEM_USER_LIST, params }
}
export const systemUserListFail = (response) => {
    return { type: SYSTEM_USER_LIST_FAIL, response }
}
export const systemUserListSuccess = (response) => {
    return { type: SYSTEM_USER_LIST_SUCCESS, response }
}
export const systemUserListReset = () => {
    return { type: SYSTEM_USER_LIST_RESET }
}