export const GET_PAYMENT_HISTORY_LIST = "GET_PAYMENT_HISTORY_LIST"
export const GET_PAYMENT_HISTORY_LIST_FAIL = "GET_PAYMENT_HISTORY_LIST_FAIL"
export const GET_PAYMENT_HISTORY_LIST_SUCCESS = "GET_PAYMENT_HISTORY_LIST_SUCCESS"
export const GET_PAYMENT_HISTORY_LIST_RESET = "GET_PAYMENT_HISTORY_LIST_RESET"

export const getPaymentHistoryList = (params) => {
    return { type: GET_PAYMENT_HISTORY_LIST, params }
}
export const getPaymentHistoryListFail = (response) => {
    return { type: GET_PAYMENT_HISTORY_LIST_FAIL, response }
}
export const getPaymentHistoryListSuccess = (response) => {
    return { type: GET_PAYMENT_HISTORY_LIST_SUCCESS, response }
}
export const getPaymentHistoryListReset = () => {
    return { type: GET_PAYMENT_HISTORY_LIST_RESET }
}