export const DELETE_SAMPLE = "DELETE_SAMPLE"
export const DELETE_SAMPLE_FAIL = "DELETE_SAMPLE_FAIL"
export const DELETE_SAMPLE_SUCCESS = "DELETE_SAMPLE_SUCCESS"
export const DELETE_SAMPLE_RESET = "DELETE_SAMPLE_RESET"

export const deleteSample = (params) => {
    return { type: DELETE_SAMPLE, params }
}
export const deleteSampleFail = (response) => {
    return { type: DELETE_SAMPLE_FAIL, response }
}
export const deleteSampleSuccess = (response) => {
    return { type: DELETE_SAMPLE_SUCCESS, response }
}
export const deleteSampleReset = () => {
    return { type: DELETE_SAMPLE_RESET }
}