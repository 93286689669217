
import { 
    GET_NEW_DOCTOR_LIST,
    GET_NEW_DOCTOR_LIST_SUCCESS,
    GET_NEW_DOCTOR_LIST_FAIL, 
    GET_NEW_DOCTOR_LIST_RESET,
} from "../action/getNewDoctorListAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_NEW_DOCTOR_LIST:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_NEW_DOCTOR_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_NEW_DOCTOR_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_NEW_DOCTOR_LIST_RESET:
            return initialState;
        default: 
            return state;
    }
};

