export const CHANGE_TESTIMONIAL_STATUS = "CHANGE_TESTIMONIAL_STATUS"
export const CHANGE_TESTIMONIAL_STATUS_FAIL = "CHANGE_TESTIMONIAL_STATUS_FAIL"
export const CHANGE_TESTIMONIAL_STATUS_SUCCESS = "CHANGE_TESTIMONIAL_STATUS_SUCCESS"
export const CHANGE_TESTIMONIAL_STATUS_RESET = "CHANGE_TESTIMONIAL_STATUS_RESET"

export const changeTestimonialStatus = (params) => {
    return { type: CHANGE_TESTIMONIAL_STATUS, params }
}
export const changeTestimonialStatusFail = (response) => {
    return { type: CHANGE_TESTIMONIAL_STATUS_FAIL, response }
}
export const changeTestimonialStatusSuccess = (response) => {
    return { type: CHANGE_TESTIMONIAL_STATUS_SUCCESS, response }
}
export const changeTestimonialStatusReset = () => {
    return { type: CHANGE_TESTIMONIAL_STATUS_RESET }
}