export const GET_TEST_BOOK_LIST = "GET_TEST_BOOK_LIST"
export const GET_TEST_BOOK_LIST_FAIL = "GET_TEST_BOOK_LIST_FAIL"
export const GET_TEST_BOOK_LIST_SUCCESS = "GET_TEST_BOOK_LIST_SUCCESS"
export const GET_TEST_BOOK_LIST_RESET = "GET_TEST_BOOK_LIST_RESET"

export const getTestBookList = (params) => {
    return { type: GET_TEST_BOOK_LIST, params }
}
export const getTestBookListFail = (response) => {
    return { type: GET_TEST_BOOK_LIST_FAIL, response }
}
export const getTestBookListSuccess = (response) => {
    return { type: GET_TEST_BOOK_LIST_SUCCESS, response }
}
export const getTestBookListReset = () => {
    return { type: GET_TEST_BOOK_LIST_RESET }
}