export const GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST = "GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST"
export const GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_FAIL = "GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_FAIL"
export const GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_SUCCESS = "GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_SUCCESS"
export const GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_RESET = "GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_RESET"

export const getQuickSupportQuestionAnswerList = (params) => {
    return { type: GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST, params }
}
export const getQuickSupportQuestionAnswerListFail = (response) => {
    return { type: GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_FAIL, response }
}
export const getQuickSupportQuestionAnswerListSuccess = (response) => {
    return { type: GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_SUCCESS, response }
}
export const getQuickSupportQuestionAnswerListReset = () => {
    return { type: GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST_RESET }
}