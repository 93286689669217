// action
import {
    SAVE_USER_RIGHTS,
    SAVE_USER_RIGHTS_FAIL,
    SAVE_USER_RIGHTS_SUCCESS,
    SAVE_USER_RIGHTS_RESET
} from '../action/saveUserRightsAction'

// initialState
const initialState = {
    apiState: "",
    list: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_USER_RIGHTS:
            return {
                ...state,
                apiState: "loading"
            }
        case SAVE_USER_RIGHTS_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case SAVE_USER_RIGHTS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data
            }
        case SAVE_USER_RIGHTS_RESET:
            return initialState
        default:
            return state
    }
}