export const GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL = "GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL"
export const GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_FAIL = "GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_FAIL"
export const GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_SUCCESS = "GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_SUCCESS"
export const GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_RESET = "GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_RESET"

export const getSampleContainerTestMapDetail = (params) => {
    return { type: GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL, params }
}
export const getSampleContainerTestMapDetailFail = (response) => {
    return { type: GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_FAIL, response }
}
export const getSampleContainerTestMapDetailSuccess = (response) => {
    return { type: GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_SUCCESS, response }
}
export const getSampleContainerTestMapDetailReset = () => {
    return { type: GET_SAMPLE_CONTAINER_TEST_MAP_DETAIL_RESET }
}