// action
import {
    GET_PAYMENT_HISTORY_COUPON,
    GET_PAYMENT_HISTORY_COUPON_FAIL,
    GET_PAYMENT_HISTORY_COUPON_SUCCESS,
    GET_PAYMENT_HISTORY_COUPON_RESET
} from '../action/getPaymentHistoryCouponAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    list: [],
    total_records: 0,
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_HISTORY_COUPON:
            return {
                ...state,
                apiState: "loading"
            }

        case GET_PAYMENT_HISTORY_COUPON_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }

        case GET_PAYMENT_HISTORY_COUPON_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data.data.list,
                total_records: action.response.data.data.total_records,
            }

        case GET_PAYMENT_HISTORY_COUPON_RESET:
            return initialState

        default:
            return state
    }
}