export const DELETE_ROLE_MODULE_MAPPING = "DELETE_ROLE_MODULE_MAPPING"
export const DELETE_ROLE_MODULE_MAPPING_FAIL = "DELETE_ROLE_MODULE_MAPPING_FAIL"
export const DELETE_ROLE_MODULE_MAPPING_SUCCESS = "DELETE_ROLE_MODULE_MAPPING_SUCCESS"
export const DELETE_ROLE_MODULE_MAPPING_RESET = "DELETE_ROLE_MODULE_MAPPING_RESET"

export const deleteRoleModuleMapping = (params) => {
    return { type: DELETE_ROLE_MODULE_MAPPING, params }
}
export const deleteRoleModuleMappingFail = (response) => {
    return { type: DELETE_ROLE_MODULE_MAPPING_FAIL, response }
}
export const deleteRoleModuleMappingSuccess = (response) => {
    return { type: DELETE_ROLE_MODULE_MAPPING_SUCCESS, response }
}
export const deleteRoleModuleMappingReset = () => {
    return { type: DELETE_ROLE_MODULE_MAPPING_RESET }
}