export const VIEW_DOCTOR_SIGNUP_REQUEST = "VIEW_DOCTOR_SIGNUP_REQUEST"
export const VIEW_DOCTOR_SIGNUP_REQUEST_FAIL = "VIEW_DOCTOR_SIGNUP_REQUEST_FAIL"
export const VIEW_DOCTOR_SIGNUP_REQUEST_SUCCESS = "VIEW_DOCTOR_SIGNUP_REQUEST_SUCCESS"
export const VIEW_DOCTOR_SIGNUP_REQUEST_RESET = "VIEW_DOCTOR_SIGNUP_REQUEST_RESET"

export const viewDoctorSignupRequest = (params) => {
    return { type: VIEW_DOCTOR_SIGNUP_REQUEST, params }
}
export const viewDoctorSignupRequestFail = (response) => {
    return { type: VIEW_DOCTOR_SIGNUP_REQUEST_FAIL, response }
}
export const viewDoctorSignupRequestSuccess = (response) => {
    return { type: VIEW_DOCTOR_SIGNUP_REQUEST_SUCCESS, response }
}
export const viewDoctorSignupRequestReset = () => {
    return { type: VIEW_DOCTOR_SIGNUP_REQUEST_RESET }
}