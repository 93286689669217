// action
import {
    GET_CENTRE_LOCATIONS,
    GET_CENTRE_LOCATIONS_FAIL,
    GET_CENTRE_LOCATIONS_SUCCESS,
    GET_CENTRE_LOCATIONS_RESET
} from '../action/getCentreLocationsAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    list: [],
    pagination: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CENTRE_LOCATIONS:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_CENTRE_LOCATIONS_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_CENTRE_LOCATIONS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data.data,
                pagination: action.response.data.data.pagination,
            }
        case GET_CENTRE_LOCATIONS_RESET:
            return initialState
        default:
            return state
    }
}