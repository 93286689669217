import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_SAMPLE_LIST,
    getSampleListFail,
    getSampleListSuccess
} from '../action/getSampleListAction'

// api 
import getSampleListApi from '../api/getSampleListApi'

export function* getSampleListSaga(action) {
    try {
        const response = yield call(() => getSampleListApi(action.params))
        if (response.data.status === 1) {
            yield put(getSampleListSuccess(response, action))
        } else {
            yield put(getSampleListFail(response, action))
        }
    }
    catch (e) {
        yield put(getSampleListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_SAMPLE_LIST, getSampleListSaga)
}