export const SAVE_TEST_MASTER = "SAVE_TEST_MASTER"
export const SAVE_TEST_MASTER_FAIL = "SAVE_TEST_MASTER_FAIL"
export const SAVE_TEST_MASTER_SUCCESS = "SAVE_TEST_MASTER_SUCCESS"
export const SAVE_TEST_MASTER_RESET = "SAVE_TEST_MASTER_RESET"

export const saveTestMaster = (params) => {
    return { type: SAVE_TEST_MASTER, params }
}
export const saveTestMasterFail = (response) => {
    return { type: SAVE_TEST_MASTER_FAIL, response }
}
export const saveTestMasterSuccess = (response) => {
    return { type: SAVE_TEST_MASTER_SUCCESS, response }
}
export const saveTestMasterReset = () => {
    return { type: SAVE_TEST_MASTER_RESET }
}