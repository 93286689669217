export const GET_COUPON_DETAIL = "GET_COUPON_DETAIL"
export const GET_COUPON_DETAIL_FAIL = "GET_COUPON_DETAIL_FAIL"
export const GET_COUPON_DETAIL_SUCCESS = "GET_COUPON_DETAIL_SUCCESS"
export const GET_COUPON_DETAIL_RESET = "GET_COUPON_DETAIL_RESET"

export const getCouponDetail = (params) => {
    return { type: GET_COUPON_DETAIL, params }
}
export const getCouponDetailFail = (response) => {
    return { type: GET_COUPON_DETAIL_FAIL, response }
}
export const getCouponDetailSuccess = (response) => {
    return { type: GET_COUPON_DETAIL_SUCCESS, response }
}
export const getCouponDetailReset = () => {
    return { type: GET_COUPON_DETAIL_RESET }
}