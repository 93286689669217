export const DELETE_INSTITUTE = "DELETE_INSTITUTE"
export const DELETE_INSTITUTE_FAIL = "DELETE_INSTITUTE_FAIL"
export const DELETE_INSTITUTE_SUCCESS = "DELETE_INSTITUTE_SUCCESS"
export const DELETE_INSTITUTE_RESET = "DELETE_INSTITUTE_RESET"

export const deleteInstitute = (params) => {
    return { type: DELETE_INSTITUTE, params }
}
export const deleteInstituteFail = (response) => {
    return { type: DELETE_INSTITUTE_FAIL, response }
}
export const deleteInstituteSuccess = (response) => {
    return { type: DELETE_INSTITUTE_SUCCESS, response }
}
export const deleteInstituteReset = () => {
    return { type: DELETE_INSTITUTE_RESET }
}