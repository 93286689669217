export const GET_PATIENT_DETAIL = "GET_PATIENT_DETAIL"
export const GET_PATIENT_DETAIL_FAIL = "GET_PATIENT_DETAIL_FAIL"
export const GET_PATIENT_DETAIL_SUCCESS = "GET_PATIENT_DETAIL_SUCCESS"
export const GET_PATIENT_DETAIL_RESET = "GET_PATIENT_DETAIL_RESET"

export const getPatientDetail = (params) => {
    return { type: GET_PATIENT_DETAIL, params }
}
export const getPatientDetailFail = (response) => {
    return { type: GET_PATIENT_DETAIL_FAIL, response }
}
export const getPatientDetailSuccess = (response) => {
    return { type: GET_PATIENT_DETAIL_SUCCESS, response }
}
export const getPatientDetailReset = () => {
    return { type: GET_PATIENT_DETAIL_RESET }
}