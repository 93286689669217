export const DELETE_MOU = "DELETE_MOU"
export const DELETE_MOU_FAIL = "DDELETE_MOU_FAIL"
export const DELETE_MOU_SUCCESS = "DELETE_MOU_SUCCESS"
export const DELETE_MOU_RESET = "DELETE_MOU_RESET"

export const deleteMou = (params) => {
    return { type: DELETE_MOU, params }
}
export const deleteMouFail = (response) => {
    return { type: DELETE_MOU_FAIL, response }
}
export const deleteMouSuccess = (response) => {
    return { type: DELETE_MOU_SUCCESS, response }
}
export const deleteMouReset = () => {
    return { type: DELETE_MOU_RESET }
}