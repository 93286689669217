export const GET_ROLE_DETAIL = "GET_ROLE_DETAIL"
export const GET_ROLE_DETAIL_FAIL = "GET_ROLE_DETAIL_FAIL"
export const GET_ROLE_DETAIL_SUCCESS = "GET_ROLE_DETAIL_SUCCESS"
export const GET_ROLE_DETAIL_RESET = "GET_ROLE_DETAIL_RESET"

export const getRoleDetail = (params) => {
    return { type: GET_ROLE_DETAIL, params }
}
export const getRoleDetailFail = (response) => {
    return { type: GET_ROLE_DETAIL_FAIL, response }
}
export const getRoleDetailSuccess = (response) => {
    return { type: GET_ROLE_DETAIL_SUCCESS, response }
}
export const getRoleDetailReset = () => {
    return { type: GET_ROLE_DETAIL_RESET }
}