// action
import {
    GET_CHAT_LOG,
    GET_CHAT_LOG_FAIL,
    GET_CHAT_LOG_SUCCESS,
    GET_CHAT_LOG_RESET
} from '../action/getChatLogAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    list: [],
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CHAT_LOG:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_CHAT_LOG_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_CHAT_LOG_SUCCESS:
            return {
                ...state,
                apiState: "success",
                list: action.response.data.data.list,
            }
        case GET_CHAT_LOG_RESET:
            return initialState
        default:
            return state
    }
}