export const SAVE_ARTICLE = "SAVE_ARTICLE"
export const SAVE_ARTICLE_FAIL = "SAVE_ARTICLE_FAIL"
export const SAVE_ARTICLE_SUCCESS = "SAVE_ARTICLE_SUCCESS"
export const SAVE_ARTICLE_RESET = "SAVE_ARTICLE_RESET"

export const saveArticle = (params) => {
    return { type: SAVE_ARTICLE, params }
}
export const saveArticleFail = (response) => {
    return { type: SAVE_ARTICLE_FAIL, response }
}
export const saveArticleSuccess = (response) => {
    return { type: SAVE_ARTICLE_SUCCESS, response }
}
export const saveArticleReset = () => {
    return { type: SAVE_ARTICLE_RESET }
}