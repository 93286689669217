export const SAVE_PATIENT = "SAVE_PATIENT"
export const SAVE_PATIENT_FAIL = "SAVE_PATIENT_FAIL"
export const SAVE_PATIENT_SUCCESS = "SAVE_PATIENT_SUCCESS"
export const SAVE_PATIENT_RESET = "SAVE_PATIENT_RESET"

export const savePatient = (params) => {
    return { type: SAVE_PATIENT, params }
}
export const savePatientFail = (response) => {
    return { type: SAVE_PATIENT_FAIL, response }
}
export const savePatientSuccess = (response) => {
    return { type: SAVE_PATIENT_SUCCESS, response }
}
export const savePatientReset = () => {
    return { type: SAVE_PATIENT_RESET }
}