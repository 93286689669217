// action
import {
    CHANGE_EMPLOYEE_STATUS,
    CHANGE_EMPLOYEE_STATUS_FAIL,
    CHANGE_EMPLOYEE_STATUS_SUCCESS,
    CHANGE_EMPLOYEE_STATUS_RESET
} from '../action/changeEmployeeStatus'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    message: ""
}

export default function(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EMPLOYEE_STATUS:
            return {
                ...state,
                apiState: "loading"
            }
        case CHANGE_EMPLOYEE_STATUS_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case CHANGE_EMPLOYEE_STATUS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
            }
        case CHANGE_EMPLOYEE_STATUS_RESET:
            return initialState
        default:
            return state
    }
}