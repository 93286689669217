export const GET_USER_TERM_AND_CONDITION_LIST = "GET_USER_TERM_AND_CONDITION_LIST"
export const GET_USER_TERM_AND_CONDITION_LIST_FAIL = "GET_USER_TERM_AND_CONDITION_LIST_FAIL"
export const GET_USER_TERM_AND_CONDITION_LIST_SUCCESS = "GET_USER_TERM_AND_CONDITION_LIST_SUCCESS"
export const GET_USER_TERM_AND_CONDITION_LIST_RESET = "GET_USER_TERM_AND_CONDITION_LIST_RESET"

export const getUserTermAndConditionList = (params) => {
    return { type: GET_USER_TERM_AND_CONDITION_LIST, params }
}
export const getUserTermAndConditionListFail = (response) => {
    return { type: GET_USER_TERM_AND_CONDITION_LIST_FAIL, response }
}
export const getUserTermAndConditionListSuccess = (response) => {
    return { type: GET_USER_TERM_AND_CONDITION_LIST_SUCCESS, response }
}
export const getUserTermAndConditionListReset = () => {
    return { type: GET_USER_TERM_AND_CONDITION_LIST_RESET }
}