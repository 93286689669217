import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_SAMPLE_DETAIL,
    getSampleDetailFail,
    getSampleDetailSuccess
} from '../action/getSampleDetailAction'

// api 
import getSampleDetailApi from '../api/getSampleDetailApi'

export function* getSampleDetailSaga(action) {
    try {
        const response = yield call(() => getSampleDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getSampleDetailSuccess(response, action))
        } else {
            yield put(getSampleDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getSampleDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_SAMPLE_DETAIL, getSampleDetailSaga)
}