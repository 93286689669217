export const GET_CHAT_LOG = "GET_CHAT_LOG"
export const GET_CHAT_LOG_FAIL = "GET_CHAT_LOG_FAIL"
export const GET_CHAT_LOG_SUCCESS = "GET_CHAT_LOG_SUCCESS"
export const GET_CHAT_LOG_RESET = "GET_CHAT_LOG_RESET"

export const getChatLog = (params) => {
    return { type: GET_CHAT_LOG, params }
}
export const getChatLogFail = (response) => {
    return { type: GET_CHAT_LOG_FAIL, response }
}
export const getChatLogSuccess = (response) => {
    return { type: GET_CHAT_LOG_SUCCESS, response }
}
export const getChatLogReset = () => {
    return { type: GET_CHAT_LOG_RESET }
}