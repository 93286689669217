export const ACTIVATE_DOCTOR = "ACTIVATE_DOCTOR"
export const ACTIVATE_DOCTOR_FAIL = "ACTIVATE_DOCTOR_FAIL"
export const ACTIVATE_DOCTOR_SUCCESS = "ACTIVATE_DOCTOR_SUCCESS"
export const ACTIVATE_DOCTOR_RESET = "ACTIVATE_DOCTOR_RESET"

export const activateDoctor = (params) => {
    return { type: ACTIVATE_DOCTOR, params }
}
export const activateDoctorFail = (response) => {
    return { type: ACTIVATE_DOCTOR_FAIL, response }
}
export const activateDoctorSuccess = (response) => {
    return { type: ACTIVATE_DOCTOR_SUCCESS, response }
}
export const activateDoctorReset = () => {
    return { type: ACTIVATE_DOCTOR_RESET }
}