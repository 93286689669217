export const DELETE_SAMPLE_CONTAINER_TEST_MAP = "DELETE_SAMPLE_CONTAINER_TEST_MAP"
export const DELETE_SAMPLE_CONTAINER_TEST_MAP_FAIL = "DELETE_SAMPLE_CONTAINER_TEST_MAP_FAIL"
export const DELETE_SAMPLE_CONTAINER_TEST_MAP_SUCCESS = "DELETE_SAMPLE_CONTAINER_TEST_MAP_SUCCESS"
export const DELETE_SAMPLE_CONTAINER_TEST_MAP_RESET = "DELETE_SAMPLE_CONTAINER_TEST_MAP_RESET"

export const deleteSampleContainerTestMap = (params) => {
    return { type: DELETE_SAMPLE_CONTAINER_TEST_MAP, params }
}
export const deleteSampleContainerTestMapFail = (response) => {
    return { type: DELETE_SAMPLE_CONTAINER_TEST_MAP_FAIL, response }
}
export const deleteSampleContainerTestMapSuccess = (response) => {
    return { type: DELETE_SAMPLE_CONTAINER_TEST_MAP_SUCCESS, response }
}
export const deleteSampleContainerTestMapReset = () => {
    return { type: DELETE_SAMPLE_CONTAINER_TEST_MAP_RESET }
}