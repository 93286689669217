export const GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY"
export const GET_PAYMENT_HISTORY_FAIL = "GET_PAYMENT_HISTORY_FAIL"
export const GET_PAYMENT_HISTORY_SUCCESS = "GET_PAYMENT_HISTORY_SUCCESS"
export const GET_PAYMENT_HISTORY_RESET = "GET_PAYMENT_HISTORY_RESET"

export const getPaymentHistory = (params) => {
    return { type: GET_PAYMENT_HISTORY, params }
}
export const getPaymentHistoryFail = (response) => {
    return { type: GET_PAYMENT_HISTORY_FAIL, response }
}
export const getPaymentHistorySuccess = (response) => {
    return { type: GET_PAYMENT_HISTORY_SUCCESS, response }
}
export const getPaymentHistoryReset = () => {
    return { type: GET_PAYMENT_HISTORY_RESET }
}