import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_INSTITUTE_TEST_PRICING_MASTER,
    saveInstituteTestPricingMasterFail,
    saveInstituteTestPricingMasterSuccess
} from '../action/saveInstituteTestPricingMasterAction'

// api 
import saveInstituteTestPricingMasterApi from '../api/saveInstituteTestPricingMasterApi'

export function* saveInstituteTestPricingMasterSaga(action) {
    try {
        const response = yield call(() => saveInstituteTestPricingMasterApi(action.params))
        if (response) {
            yield put(saveInstituteTestPricingMasterSuccess(response, action))
        }
        else {
            yield put(saveInstituteTestPricingMasterFail(response, action))
        }
    }
    catch (e) {
        yield put(saveInstituteTestPricingMasterFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_INSTITUTE_TEST_PRICING_MASTER, saveInstituteTestPricingMasterSaga)
}