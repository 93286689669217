// action
import {
    DELETE_INSTITUTE,
    DELETE_INSTITUTE_FAIL,
    DELETE_INSTITUTE_SUCCESS,
    DELETE_INSTITUTE_RESET
} from '../action/deleteInstituteAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DELETE_INSTITUTE:
            return {
                ...state,
                apiState: "loading"
            }
        case DELETE_INSTITUTE_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case DELETE_INSTITUTE_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
            }
        case DELETE_INSTITUTE_RESET:
            return initialState
        default:
            return state
    }
}