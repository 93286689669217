export const GET_DOCTOR_LIST = "GET_DOCTOR_LIST"
export const GET_DOCTOR_LIST_FAIL = "GET_DOCTOR_LIST_FAIL"
export const GET_DOCTOR_LIST_SUCCESS = "GET_DOCTOR_LIST_SUCCESS"
export const GET_DOCTOR_LIST_RESET = "GET_DOCTOR_LIST_RESET"

export const getDoctorList = (params) => {
    return { type: GET_DOCTOR_LIST, params }
}
export const getDoctorListFail = (response) => {
    return { type: GET_DOCTOR_LIST_FAIL, response }
}
export const getDoctorListSuccess = (response) => {
    return { type: GET_DOCTOR_LIST_SUCCESS, response }
}
export const getDoctorListReset = () => {
    return { type: GET_DOCTOR_LIST_RESET }
}