export const GET_PREGNANCY_HIGHLIGHTS_LIST = "GET_PREGNANCY_HIGHLIGHTS_LIST"
export const GET_PREGNANCY_HIGHLIGHTS_LIST_FAIL = "GET_PREGNANCY_HIGHLIGHTS_LIST_FAIL"
export const GET_PREGNANCY_HIGHLIGHTS_LIST_SUCCESS = "GET_PREGNANCY_HIGHLIGHTS_LIST_SUCCESS"
export const GET_PREGNANCY_HIGHLIGHTS_LIST_RESET = "GET_PREGNANCY_HIGHLIGHTS_LIST_RESET"

export const getPregnancyHighlightsList = (params) => {
    return { type: GET_PREGNANCY_HIGHLIGHTS_LIST, params }
}
export const getPregnancyHighlightsListFail = (response) => {
    return { type: GET_PREGNANCY_HIGHLIGHTS_LIST_FAIL, response }
}
export const getPregnancyHighlightsListSuccess = (response) => {
    return { type: GET_PREGNANCY_HIGHLIGHTS_LIST_SUCCESS, response }
}
export const getPregnancyHighlightsListReset = () => {
    return { type: GET_PREGNANCY_HIGHLIGHTS_LIST_RESET }
}