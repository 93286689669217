export const GET_COMMON_MASTER_LIST = "GET_COMMON_MASTER_LIST"
export const GET_COMMON_MASTER_LIST_FAIL = "GET_COMMON_MASTER_LIST_FAIL"
export const GET_COMMON_MASTER_LIST_SUCCESS = "GET_COMMON_MASTER_LIST_SUCCESS"
export const GET_COMMON_MASTER_LIST_RESET = "GET_COMMON_MASTER_LIST_RESET"

export const getCommonMasterList = (params) => {
    return { type: GET_COMMON_MASTER_LIST, params }
}
export const getCommonMasterListFail = (response) => {
    return { type: GET_COMMON_MASTER_LIST_FAIL, response }
}
export const getCommonMasterListSuccess = (response) => {
    return { type: GET_COMMON_MASTER_LIST_SUCCESS, response }
}
export const getCommonMasterListReset = () => {
    return { type: GET_COMMON_MASTER_LIST_RESET }
}

//  CommonMaster